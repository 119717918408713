/* Fields: input */
.#{$form-namespace}Form-field--input,
.#{$form-namespace}Form-field--textarea {
    @extend %Form-field--reset;
    @extend %Form-field--shadow;
    max-width: 100%;
    box-shadow: none;
    padding: 14px 23px;
    border: 1px solid rgba(58, 58, 58, 0.2);
    line-height: normal;
}

// .#{$form-namespace}Form-field--input {
//     padding: em($form-element-padding--t, $form-element-padding--r, $form-element-padding--b, $form-element-padding--l);
// }

// .#{$form-namespace}Form-field--textarea {
//     padding: em($form-element-padding--t - 2, $form-element-padding--r, $form-element-padding--b - 2, $form-element-padding--l);
// }