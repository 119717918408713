.Form-indicator {
    top: 0 !important;
}

.ContactPage {
    background-color: $cc-grey--light;

    .Quote {
        padding-bottom: u($spacing-unit) !important;
        background-color: $cc-grey--light;

        >.Container {
            max-width: u($structure-width);
            text-align: left;

            h1 {
                max-width: 680px;
            }

            &::before,
            &::after {
                left: 0;
            }
        }
    }

    .Contact {
        padding-bottom: u($spacing-unit * 3) !important;

        &-item {
            @include mq($from: $viewport--sm) {
                margin-left: u($spacing-unit * 3)
            }

            .icon {
                width: 19px;
                height: 19px;
                opacity: .2;
                margin-right: 19px;

                * {
                    fill: $cc-black;
                    opacity: 1;
                }
            }
        }

        &-link {
            font-size: 17px;
            color: $cc-black;
            font-weight: 600;
        }


    }

    .Formulier {
        background-color: $cc-blue;
        padding-top: u($spacing-unit * 4);
        padding-bottom: u($spacing-unit * 4);

        .TitleBig {
            &::before {
                background-color: $white;
            }

            h4 {
                color: $white;
            }

        }

        >.Container {
            display: flex;
            flex-wrap: wrap;
        }

        .Form {
            background-color: $white;
            padding: 40px 20px;
            max-width: 680px;
            width: 100%;

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit * 2);
                padding: 40px 80px;
            }


            .Form-item {
                &.radio_group {
                    background-color: $cc-grey--light;
                    padding: 20px 0;
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    .Form-controls {
                        margin-top: 10px;
                    }

                    .Form-field--radio {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }

                    .Form-indicator {
                        border-radius: 0;
                        top: 0 !important;

                        &::after {
                            display: none;
                        }
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        height: 100%;

                        @include mq($from: $viewport--md) {
                            width: 80px;
                        }

                        width: 20px;
                        background-color: $cc-grey--light;
                    }

                    &::before {
                        @include mq($from: $viewport--md) {
                            left: -80px;
                        }

                        left: -20px;
                    }

                    &::after {
                        @include mq($from: $viewport--md) {
                            right: -80px;
                        }

                        right: -20px;
                    }
                }
            }
        }

        .Nav--socialMedia {
            margin-top: 0;

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit * 3);
            }

            &--Title {
                color: $white;
            }

            .Nav-icon {
                * {
                    transition: opacity .4s;
                }

                &:hover {
                    circle {
                        fill: $white;
                        opacity: .5;
                    }

                    * {
                        fill: $cc-blue;
                    }
                }

                circle {
                    fill: $white;
                }

                * {
                    fill: $cc-blue;
                }
            }
        }
    }

    .CtaOverview {
        margin-top: 0 !important;
    }

}
