/* General */
.Main {
    margin-top: 164px;

    @include mq($until: $viewport--sm) {
        margin-top: 155px;
    }

    transition: margin .6s;
}

body {
    @include mq ($until: $viewport--lg - 1) {
        .show-nav & {
            overflow: hidden;
        }
    }
}

// TitleBig
.TitleBig {
    color: $cc-black !important;
    opacity: .3;
    margin-bottom: u($spacing-unit * 1.25);

    h4 {
        font-family: $font-azoSans;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    position: relative;

    &::before {
        content: "";
        position: absolute;
        background-color: currentColor;
        height: 1px;
        top: 50%;

        @include mq($from: $viewport--xl) {
            left: calc(((100vw - #{$structure-width}px) / 2) * -1);
            width: calc(((100vw - #{$structure-width}px) - 40px) / 2);
        }

        @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
            left: -40px;
            width: 20px;
        }

        @include mq($until: $viewport--md - 1) {
            left: -20px;
            width: 10px;
        }
    }

}

// Title
.Title {
    display: flex;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        background-color: currentColor;
        height: 1px;
        top: 50%;

        @include mq($from: $viewport--xl) {
            left: calc(((100vw - #{$structure-width}px) / 2) * -1);
            width: calc(((100vw - #{$structure-width}px) - 40px) / 2);
        }

        @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
            left: -40px;
            width: 20px;
        }

        @include mq($until: $viewport--md - 1) {
            left: -20px;
            width: 10px;
        }
    }

    .icon-category {
        margin-right: 13px;
        width: 33px;
        height: 19px;

        * {
            fill: currentColor;
        }
    }

    h4 {
        text-transform: uppercase;
        font-family: $font-sofiaPro;
        font-weight: 900;
        margin: 0;
    }
}

// Services
.Services {
    @include mq($from: $viewport--sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }

    text-align: center;

    &-item {

        position: relative;
        font-weight: medium;
        color: $cc-black;
        opacity: .5;
        letter-spacing: 1px;
        font-size: 15px;
        padding: 18px 12px;

        @include mq($from: $viewport--sm) {
            padding: 0 12px;
        }

        margin: 0 12px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $cc-grey;

            @include mq($until: $viewport--sm - 1) {
                left: calc(50% - 4px);
                top: 0;
            }

            @include mq($from: $viewport--sm) {
                left: -16px;
                top: calc(50% - 4px);
            }
        }

        &::after {
            @include mq($until: $viewport--sm - 1) {
                left: calc(50% - 4px);
                top: 100%;
            }

            @include mq($from: $viewport--sm) {
                right: -16px;
                left: calc(100% - -8px);
            }
        }
    }
}

// svg-media
.svg-media {
    margin-top: auto;
    position: relative;

    @supports(mask-image: url('../img/icons/icon-category.svg')) {
        @include mq ($from: $viewport--lg) {
            width: 100%;
            max-width: 729px;
            margin-bottom: -40px;
            top: -40px;
        }
    }

    &::before {
        @include mq ($from: $viewport--lg) {
            width: calc(87% + 6px);
            left: -9px;
            clip-path: polygon(1.75% 0%, 100% 0%, 98% 100%, 0% 100%);
        }

        @include mq ($until: $viewport--lg - 1) {
            width: 100%;
            left: 0;
        }

        bottom: -10px;
        content: '';
        position: absolute;
        height: 10px;
        background-image: $cc-gradient;
        // @include mq ($until: $viewport--lg - 1) {
        //     width: calc(88% + 4px);
        //     left: -6px;
        // }
    }

    &--img {
        @supports(mask-image: url('../img/icons/icon-category.svg')) {
            @include mq ($from: $viewport--lg) {
                mask-image: url('../img/icons/icon-category.svg');
                mask-mode: alpha;
                mask-repeat: no-repeat;
                mask-size: 100%;
                position: relative;
                top: 3px;
            }
        }

        @include mq ($until: $viewport--lg - 1) {
            height: 100%;
            object-fit: cover;
            border-radius: 60px 0 0 0;
        }
    }
}

// Crosses background
.CrossBG {
    position: relative;

    &::before,
    &::after {

        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><image width="10" height="10" xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cCA2PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJWaXN1YWwtRGVzaWduIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJIb21lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC4wMDAwMDAsIC0zMDUuMDAwMDAwKSIgZmlsbD0iI0Q4RDhEOCI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuMDAwMDAwLCAzMDUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjUiIHk9IjAiIHdpZHRoPSIyIiBoZWlnaHQ9IjEyIj48L3JlY3Q+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtNi4wMDAwMDApICIgeD0iNSIgeT0iMCIgd2lkdGg9IjIiIGhlaWdodD0iMTIiPjwvcmVjdD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=" /></svg>');
        content: '';
        position: absolute;
        top: 15%;
        height: 75%;

        @include mq($from: $viewport--xl) {
            width: calc(((100vw - #{$structure-width}px) - 20px) / 2);
        }

        @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
            width: 40px;
        }

        width: 20px;
    }

    &::before {
        left: -20px;

        @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
            left: -40px;
        }

        @include mq($from: $viewport--xl) {
            left: calc((((100vw - #{$structure-width}px) - 20px) / 2) * -1);
        }
    }

    &::after {
        right: -20px;

        @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
            right: -40px;
        }

        @include mq($from: $viewport--xl) {
            right: calc((((100vw - #{$structure-width}px) - 20px) / 2) * -1);
        }
    }
}

// Title-line
.Title-line {
    text-transform: uppercase;
    opacity: .3;
    color: $cc-black;
    letter-spacing: 1.5px;
    font-weight: 500;
    position: relative;
    margin-left: u($spacing-unit * 4);
    text-align: left;

    &::before {
        position: absolute;
        content: "";
        height: 2px;
        width: 120px;
        background-color: rgba(58, 58, 58, 0.3);
        left: calc(-120px + -40px);
        top: calc(50% - 1px);
    }
}

// CategortiesOverview
.CategortiesOverview {
    background-color: $cc-grey--light;
    padding-top: u($spacing-unit * 4.25);
    padding-bottom: u($spacing-unit * 2.25);

    .Categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

    }

    .Categorie {
        width: 100%;

        @include mq($from: $viewport--sm) {
            max-width: calc((100% / 2) - 20px);
        }

        @include mq($from: $viewport--lg) {
            max-width: calc((100% / 3) - 20px);
        }

        background-color: $white;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 30px 22px 30px;
        position: relative;
        margin-bottom: u($spacing-unit * 2);

        &-title {
            text-transform: uppercase;
            letter-spacing: 1.2px;
            font-weight: 900;
        }

        p,
        br,
        em,
        strong,
        ul,
        ol,
        li {
            color: $black;
        }

        ul {
            margin-left: 15px;
        }

        p {
            margin-bottom: 10px !important;
        }

        .icon-category {
            position: absolute;
            width: 40px;
            height: 23px;
            left: 30px;
            top: -23px;

        }

        .Button {
            margin-top: auto;
        }
    }
}

// NewsOverview
.NewsOverview {
    padding-top: u($spacing-unit * 4.25);
    padding-bottom: u($spacing-unit * 2.25);
    text-align: center;

    .newsItem {


        text-align: left;
        border-radius: 60px 0 0 0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

        @include mq($from: $viewport--md) {
            display: flex;
            justify-content: space-between;
        }


        &-media {

            @include mq($from: $viewport--md) {
                width: 50%;
            }

            padding: 0 !important;
            position: relative;


            &::before {
                content: '';
                position: absolute;
                width: 100%;
                left: 0x;
                height: 10px;
                bottom: -10px;
                background-image: $cc-gradient;
            }

            img {
                border-radius: 60px 0 0 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &-content {


            @include mq($from: $viewport--md) {
                width: 50%;
                padding-right: u($spacing-unit * 2);
                padding-left: u($spacing-unit * 2);
            }

            @include mq($from: $viewport--lg) {
                padding-right: u($spacing-unit * 4);
                padding-left: u($spacing-unit * 4);
            }

            padding-top: u($spacing-unit * 2);
            padding-bottom: u($spacing-unit * 1.5);
            padding-right: u($spacing-unit);
            padding-left: u($spacing-unit);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

        }

        &-title {
            text-transform: uppercase;
            letter-spacing: 1.2px;
            color: $cc-blue;
            font-weight: 900;
        }
    }
}

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}
