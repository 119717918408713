.Quote {

    >.Container {
        max-width: 650px;
        margin: 0 auto;
        text-align: center;
        padding-top: u($spacing-unit * 1.5);
        padding-bottom: u($spacing-unit * 1.5);
        position: relative;

        &::before,
        &::after {
            position: absolute;
            content: "";
            width: 60px;
            height: 3px;
            background-color: $cc-blue;
            left: calc(50% - 30px);
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }


        h1 {
            font-family: $font-poynter;
            font-size: 28px;
            font-style: italic;
            color: $cc-blue;
            margin-bottom: 10px;
        }
    }
}

.ProductsPage {
    .Info {
        background-color: $white !important;
        padding-top: u($spacing-unit * 4);
        padding-bottom: u($spacing-unit * 4) !important;
    }

    .RelatedProducts {
        padding-bottom: u($spacing-unit * 4);
    }
}

.CategoriesPage,
.ProductsPage,
.AccountPage,
.NewsOverviewPage {
    &.StandardPage {
        .Info {
            background-color: $white;
        }
    }

    background-color: $cc-grey--light;

    .Products {
        &-item {
            margin-bottom: u($spacing-unit);


            .Inner {
                background-color: #d8d8d8;
                display: flex;
                flex-direction: column;
                height: 100%;
                border-radius: 40px 0 0 0;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
                position: relative;

                &:hover {
                    .Products-item--media {

                        &::before,
                        &::after {
                            opacity: 1;
                        }
                    }

                    .Media-image,
                    svg {
                        transform: scale(1.2);
                    }
                }

                .Label {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: flex-end;

                    &-item {
                        border: 2px solid $white;
                        border-radius: 5px;
                        color: $white;
                        padding: 2px 10px;
                        font-weight: 600;
                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }

                    &-herstellingen {
                        background-color: $cc-green--gradient;
                        margin-bottom: 3px;
                    }

                    &-refurbished {
                        background-color: $cc-blue;
                        margin-bottom: 3px;
                    }

                    &-news {
                        background-color: #e15a8c;
                        margin-bottom: 3px;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }

            &--media {
                height: 100%;
                min-height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 40px 0 0 0;
                overflow: hidden;
                position: relative;
                padding: u(20);

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: opacity .6s;
                }

                &::before {
                    background-color: rgba(58, 58, 58, 0.3);
                    z-index: 1;
                }

                &::after {
                    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzNXB4IiBoZWlnaHQ9IjE0cHgiIHZpZXdCb3g9IjAgMCAzNSAxNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iYnV0dG9uLWJsdWUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xOS4wMDAwMDAsIC0xMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC03Ij4gICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOS4wMDAwMDAsIDguMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMzAuNzA5NTgyMSw3LjcgTDI2LjgzMjkzODMsMy45MTA3OTg4OSBDMjYuMzg5MDkwMSwzLjQ3Nzk3MzY1IDI2LjM4OTA5MDEsMi43NjAwMDMxMyAyNi44MzI1MjA5LDIuMzI2NTc0NTUgQzI3LjI3Nzg1NzYsMS44OTEyODMwNSAyNy45OTQyMTI0LDEuODkwMTQwNDEgMjguNDMxOTQ2OSwyLjMyOTE1Njg2IEwzNC4wNTI2ODIsNy44MjM3Nzg2NyBDMzQuNTEzMjc3LDguMjU3NzYwNjQgMzQuNTEzMjc3LDguOTg1Mjk0NjMgMzQuMDU5Mjk2LDkuNDEzOTQyODMgTDI4LjQzNTgwNzIsMTQuOTEwNDY4MyBDMjcuOTk4OTM2OSwxNS4zNTM0NDc2IDI3LjI3OTEwMDUsMTUuMzUzNDQ3NiAyNi44MzI5MzgzLDE0LjkxNzM0ODcgQzI2LjM5MDEwNTUsMTQuNDg1NTEzNiAyNi4zOTAxMDU1LDEzLjc3MTcyODQgMjYuODMyODU0NSwxMy4zMjM5NTEzIEwzMC41NDczNTk1LDkuNyBMMSw5LjcgQzAuNDQ3NzE1MjUsOS43IDcuNDYwNjk4NzNlLTE0LDkuMjUyMjg0NzUgNy40NjA2OTg3M2UtMTQsOC43IEM3LjQ2MDY5ODczZS0xNCw4LjE0NzcxNTI1IDAuNDQ3NzE1MjUsNy43IDEsNy43IEwzMC43MDk1ODIxLDcuNyBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
                    background-repeat: no-repeat;
                    background-position: center;
                    z-index: 2
                }

                img {
                    width: 100%;
                }

                svg,
                img {
                    transition: transform .6s;
                }
            }

            &--title {
                background-color: $white;
                padding: 10px 20px;
                min-height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $cc-black !important;
                position: relative;
                text-align: center;

                .Date {
                    position: absolute;
                    top: -30px;
                    color: $white;
                    font-size: 14px;
                    font-weight: 600;
                    left: 0;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .RelatedProducts {
        background-color: $white;

        .Title {
            color: $cc-black !important;
            opacity: .3;

            h4 {
                font-family: $font-azoSans;
                font-size: 26px;
                font-weight: 500;

                letter-spacing: 1.5px;
                text-transform: uppercase;
            }

        }
    }

    .Sectors {
        margin: 0 auto;
        max-width: 1100px;

        &-title {
            font-weight: 900;
            letter-spacing: 1.2px;
            text-transform: uppercase;
        }

        .Column-4 {
            @include mq ($until: 439px) {
                column-count: 1;
            }

            @include mq ($from: 440px) {
                column-count: 2;
            }

            @include mq ($from: $viewport--sm) {
                column-count: 3;
            }

            @include mq ($from: $viewport--lg) {
                column-count: 4;
            }
        }

        .Column-3 {
            @include mq ($until: 439px) {
                column-count: 1;
            }

            @include mq ($from: 440px) {
                column-count: 2;
            }

            @include mq ($from: $viewport--sm) {
                column-count: 3;
            }

        }

        .Column-2 {
            @include mq ($until: 439px) {
                column-count: 1;
            }

            @include mq ($from: 440px) {
                column-count: 2;
            }
        }

        .Column-1 {
            column-count: 1;
        }
    }

    .Brands {
        display: flex;
        align-items: center;

        .Slider {

            margin: 0 20px;


            width: 100%;
        }

        &-button {
            &.is-disabled {
                // display: none;
                opacity: .2;
                transition: opacity .6s;
            }


            &--next {
                transform: rotate(180deg);
            }
        }

        &-item {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            &--noLink {
                pointer-events: none;
                cursor: default;
            }

            &:hover {
                .Brands-img {
                    filter: grayscale(0%);
                    opacity: 1;
                }
            }
        }

        &-img {
            filter: grayscale(100%);
            opacity: .3;
            transition: filter .6s, opacity .6s;
            max-width: 100px;
            max-height: 60px;

            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .Info {
        background-color: $cc-grey--light;
        // padding-top: u($spacing-unit * 3);
        padding-bottom: u($spacing-unit * 3);

        &-White {
            background-color: $white;
        }

        .Container {}

        .Content {
            background-color: $cc-blue;
            text-align: center;
            color: $white;
            font-size: 18px;
            padding-top: u($spacing-unit * 2.5);
            padding-bottom: u($spacing-unit * 2.5);
            padding-left: 20px;
            padding-right: 20px;
        }

        .Contact {
            @include mq($from: $viewport--sm) {
                display: flex;
                justify-content: center;
                align-items: center;
            }


            &-item {
                justify-content: center;
                align-items: center;
                display: flex;
                margin: 0;


                &:nth-child(2) {
                    @include mq($until: $viewport--sm - 1) {
                        margin-top: 10px;
                    }

                    @include mq($from: $viewport--sm) {
                        margin-left: 60px;
                        position: relative;

                        &::before {
                            position: absolute;
                            content: "";
                            height: 100%;
                            width: 2px;
                            background-color: $white;
                            opacity: .4;
                            left: -30px;
                            top: 0;
                        }
                    }
                }

                .icon-phone,
                .icon-mail {
                    margin-right: 10px;

                    * {
                        fill: $white;
                        opacity: 1;
                    }
                }
            }

            &-link {
                color: $white;

                strong {
                    font-weight: 600;
                }
            }
        }
    }

}

.InterviewsProduct{

    .Slider-button--next{
        right: -45px;
        left: auto;

        @include mq($until: $viewport--lg) {
            right: 20px;
        }
    }

    .Slider-button--prev {
        right: auto;
        left: -45px;
        transform: rotate(180deg);

        @include mq($until: $viewport--lg) {
            left: 20px;
        }
    }

    .Slider-item{
        padding-left: 2px;
        padding-right: 2px;
    }

    &-content{
        max-width: u(840px);
        margin: 0 auto;
        background: $white;
        padding: u(60px) u(82px);
        position: relative;
        z-index: 2;

        @include mq($until: $viewport--md) {
            padding: u(30px) u(60px);
        }
    }

    &-BGcross{
        max-width: u(840px);
        margin: 0 auto;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><image width="10" height="10" xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cCA2PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJWaXN1YWwtRGVzaWduIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJIb21lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC4wMDAwMDAsIC0zMDUuMDAwMDAwKSIgZmlsbD0iI0Q4RDhEOCI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuMDAwMDAwLCAzMDUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjUiIHk9IjAiIHdpZHRoPSIyIiBoZWlnaHQ9IjEyIj48L3JlY3Q+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtNi4wMDAwMDApICIgeD0iNSIgeT0iMCIgd2lkdGg9IjIiIGhlaWdodD0iMTIiPjwvcmVjdD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=" /></svg>');
        position: absolute;
        bottom: -55px;
        right: 165px;
        height: 185px;
        width: u(840px);
        z-index: 1;
        background-position-y: bottom;
    }

    &-title {
        color: $cc-blue;
        font-size: u(26px);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.53px;
        text-transform: uppercase;
        margin-bottom: u(10px);

        @include mq($until: $viewport--md) {
            font-size: 18px;
        }
    }

    &-titleQuote{
        padding-bottom: u(21px);
    }

    &-personInfo{
        font-size: u(14px);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
    }

    &-place{
        font-size: u(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        padding-top: u(5px);;
    }

    &-linkContainer{
        padding-top: u(40px);
    }
}
