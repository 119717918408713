.ContentBlocks {
    padding-top: u($spacing-unit * 4);
    padding-bottom: u($spacing-unit * 4);


    .Content-title {
        text-transform: uppercase;
        color: $cc-black;
        opacity: .3;
        position: relative;
        margin-left: 20px;
        &::before {
            content: "";
            position: absolute;
            height: 2px;
            top: 50%;
            background-color: $cc-black;
            opacity: .3;
            left: -40px;
            width: 20px;

            @include mq ($from: $viewport--md) {
                left: -60px; 
                width: 40px;
            }
        }
    }

    .ContentBlock-media {
        margin-top: auto;
        position: relative;
        margin-bottom: 10px;
        border-radius: 60px 0 0 0;

        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 10px;
            bottom: -10px;
            left: 0;
            background-image: $cc-gradient;
        }

        img {
            border-radius: 60px 0 0 0;
        }
    }

    .ContentBlock {
        &-left {
            flex-direction: row-reverse;

            .TitleBig {
                @include mq ($from: $viewport--lg) {
                    &::before {
                        display: none;
                    }
                }

            }
        }

        p {
            em {
                opacity: .5;
            }
        }

        .Grid-cell {
            @include mq ($from: $viewport--lg) {
                &:first-child {
                    margin-top: 30px;
                }
            }
        }

        .ContentBlock-media {
            @include mq ($from: $viewport--lg) {
                height: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .Grid-cell {
            display: flex;
            flex-direction: column;

            &:first-child {
                align-items: flex-start;

                @include mq ($until: $viewport--lg - 1) {
                    margin-bottom: 20px;
                }
            }

        }

    }

}
