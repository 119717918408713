/* Footer */
.Footer {
    position: relative;
    padding-top: u($spacing-unit * 1.5);
    background-color: $white;
    text-align: left;
    overflow: hidden;

    &-title {
        text-transform: uppercase;
        font-family: $font-sofiaPro;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: .9px;
    }

    &-link {
        color: $cc-black;

        &:hover {
            color: $cc-black;
        }
    }

    &-item {
        .Footer-link {
            margin-left: 15px;
        }

        margin-bottom: 5px;
    }

    @include mq ($until: $viewport--md - 1) {
        .Grid-cell {
            margin-bottom: u($spacing-unit);
        }

    }

    .Description {

        p {
            max-width: 340px;
            ;
        }

    }

    &::before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background-image: $cc-gradient;
    }

    // Top
    &-top {
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit * 1.5 + 43px);

        .Grid-cell {
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    // Bottom
    &-bottom {
        display: flex;
        flex-wrap: wrap;
        @include mq ($from: $viewport--sm) {
            align-items: center;
            justify-content: space-between;
        }
        position: relative;
       
        padding-top: u($spacing-unit / 2);
        padding-bottom: u($spacing-unit * 1.5 + 6px);

        .icon-line {
            position: absolute;
            width: u($structure-width);
            top: -43px;
            @include mq ($until: $viewport--sm - 1) {
                top: -65px;
            }
            left: 50%;
            margin-left: calc((#{$structure-width}px / 2) * -1);
        }
    }

    // Copyright
    &-copyright {
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        svg {
            fill: currentColor;
            display: block;
            margin-left: u($spacing-unit--sm);
            height: u(25px);
            width: u(20px);
        }
    }
}
