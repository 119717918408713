.Popup {
    max-width: 920px;
    width: 100%;
    padding: 40px 20px;

    @include mq ($from: 720) {
        padding: 70px 60px 180px;
    }

    &-title {
        text-transform: uppercase;
        color: $cc-blue;
        font-weight: 900;
        margin-bottom: 10px;
    }
    &-text {
        font-size: 20px;
        font-weight: 300;
    }
    &-link {

    }
    &-media {
        margin: 0 -20px;
        width: calc(100% + 40px);
        position: relative;
        bottom: -40px;


        @include mq ($from: 720) {
            position: absolute;
            bottom: -3px;
            right: 0;
            width: 400px;
            height: 225px;
            margin: 0;
        }

        @supports(mask-image: url('')) {
            @include mq ($from: 720) {
                mask-image: url('../img/icons/icon-category-alt.svg');
                mask-mode: alpha;
                mask-repeat: no-repeat;
                mask-position: 50% 100%;
                mask-size: 100%;
            }
        }

        @include mq ($from: 720) {
            picture {
                width: 100%;
                height: 100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.fancybox-slide--html {
    padding: 20px;
    @include mq ($from: 720) {
        padding: 40px;
    }
    @include mq ($from: 800) {
        padding: 80px;
    }
}

.fancybox-bg {
    background: rgba($black, .5);
}

