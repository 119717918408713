.InterviewPage {
    background-color: $cc-grey--light;

    ul {
        text-align: left;
    }

    .Interviews {
        max-width: 840px;
        margin: 0 auto;
        background-color: $white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        border-radius: 60px 0 0 0;
        margin-bottom: u($spacing-unit * 2);
        overflow: hidden;
        color: $cc-black;

        .Slider{
            border-radius: 60px 0 0 0;
        }

        .Slider-button--next{
            right: 30px;
            left: auto;
        }

        .Slider-button--prev {
            right: auto;
            left: 30px;
            transform: rotate(180deg);
        }

        &-parentContainer{
            padding-top: 0;
            padding-bottom: 0;
            //background: $white;

            @include mq ($until: $viewport--md - 1){
                display: none;
            }
        }

        &-backTopContainer{
            background: $white;
            height: u(58px);
            display: inline-flex;
            align-items: center;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                background-color: $white;
                height: u(58px);
                left: calc(((100vw - #{$structure-width}px) / 2) * -1);
                width: calc((100vw - #{$structure-width}px) / 2);

                @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
                    left: -40px;
                    width: 40px;
                }

                @include mq($until: $viewport--md - 1) {
                    left: -20px;
                    width: 20px;
                }
            }

            a,
            button,
            &-linkBank{
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $cc-black;
                opacity: .4;
                padding-right: u(30px);
                cursor: pointer;
            }
        }

        &-back{
            transform: rotate(180deg);
            margin-right: u(17px);
        }

        &-personImage{
            border: white 10px solid;
            border-radius: 100%;
        }

        &-personImgContainer{
            position: absolute;
            z-index: 5;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: white;
            border-radius: 100%;
        }

        &-personInfo{
            font-size: u(14px);
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            padding-top: u(15px);
            text-align: center;
        }

        &-place{
            font-size: u(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            padding-top: u(5px);
            text-align: center;
        }

        &-logoContainer{
            max-width: u(200px);
            background: $white;
            // padding: u(15px) u(22px);
            position: absolute;
            z-index: 2;
            top: u(80px);
            right: 15.5%;
            width: 200px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mq($until: $viewport--xl) {
                right: 10%;
            }

            @include mq($until: $viewport--lg) {
                right: 15px;
            }

            @include mq($until: $viewport--md) {
                right: 0;
            }
        }

        &-media {
            position: relative;
            margin-bottom: 70px;

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                width: 100%;
                height: 10px;
                background-image: $cc-gradient;
            }

            img {
                border-radius: 60px 0 0 0;
                width: 100%;
            }
        }

        &-content {
            padding-top: u(40px);
            padding-bottom: u(60px);
            max-width: 550px;
            margin: 0 auto;
            position: relative;

            @include mq($until: $viewport--md) {
                padding-bottom: u(20px);
                padding-left: u(20px);
                padding-right: u(20px);
            }

            &--date {
                display: block;
                font-size: 14px;
                color: $cc-blue;
                font-family: $font-sofiaPro;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: u($spacing-unit);
            }

            &--title {
                color: $cc-blue;
                font-size: u(26px);
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.53px;
                text-transform: uppercase;
            }
        }

        &-titleQuote{
            padding-bottom: u(21px);
        }

        &-line{
            max-width: u(155px);
            height: 1px;
            background: rgba(58, 58, 58, 0.3);
            transform: translate(-172px, 12px);

            @include mq($until: $viewport--sm) {
                display: none;
            }
        }

        &-intro{
            p{
                font-size: u(14px);
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
            }

            p:last-of-type{
                margin-bottom: 0;
            }
        }

        &-text,
        &-quote{
            padding-top: u(20px);
            padding-bottom: u(20px);
        }

        &-title{
            padding-top: u(20px);
        }

        &-text{
            font-size: u(14px);
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;

            p:last-of-type{
                margin-bottom: 0;
            }

            &--noPaddingT{
                padding-top: 0;
            }
        }

        &-title{
            font-size: u(14px);
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            margin-bottom: u(10px);
        }

        &-quoteText{
            font-family: $font-azoSans;
            font-size: u(22px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.48px;
            color: $cc-green--gradient;
        }

        &-quoteIconContainer{
            padding-bottom: u(20px);
        }

        &-quoteIcon{
            * {
                fill: $cc-green--gradient;
            }
        }

        &-backContainer{
            text-align: center;
        }

        .ContentBlocks {
            text-align: left;
            padding: u($spacing-unit);

            @include mq ($from: $viewport--md) {
                padding: u($spacing-unit * 2);
            }
        }
    }
}
