/* Nav */
.Nav {

    // Main
    &--main {
        margin-left: u($spacing-unit);

        @include mq ($until: $viewport--lg - 1) {
            .Nav-list {
                .headroom--not-top & {
                    margin-top: 114px;
                }
            }

            .Nav-item {
                width: 100%;
                border-top: 1px solid #f1f1f1;
                margin: 0 !important;
                text-align: left !important;
                list-style: none;
                opacity: 1 !important;

                .Nav-link {
                    padding: 15px 0 !important;
                    display: flex;
                    opacity: .8 !important;
                    margin-left: 35px;
                    cursor: pointer;
                    align-items: center;

                    .icon-next {
                        margin-left: 8px;
                        transform: rotate(90deg);
                        transition: transform .4s;

                        * {
                            fill: $black;
                            opacity: 1 !important;
                        }
                    }
                }

                &.js--child-active {
                    background-color: #f1f1f1;
                    .Nav-link {

                        .icon-next {
                            transform: rotate(-90deg);
                        }
                    }

                    .Nav--children--main {

                        display: block;
                        background-color: $cc-grey--light;

                        .Nav-item {
                            opacity: 1 !important;

                            .Nav-link {
                                margin-left: 55px !important;
                                opacity: .4 !important;
                            }

                            border-top: 1px solid #f1f1f1 !important;

                            &.is-active {
                                

                                a {
                                    color: $cc-blue !important;
                                    opacity: .8 !important;
                                }
                            }
                        }

                        .Nav-list--child {
                            margin: 0;
                        }
                    }

                }
            }

            .Nav-link {
                color: $black !important;
                line-height: 18px;
            }

            .Nav-item--lang {
                order: 1;
                display: flex;
                justify-content: center;
                background-color: $cc-blue;

                .Nav-link {
                    width: 50%;
                    justify-content: center;
                    color: $white !important;
                    opacity: 1 !important;
                    margin: 0;
                }

                .Nav--children--lang {
                    background-color: #f1f1f1;
                    width: 50%;

                    .Nav-item {
                        border: none;
                    }

                    .Nav-link {
                        width: 100%;
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        color: $cc-black !important;
                    }
                }
            }

            .Nav-item--login {
                display: block !important;

                .Nav-link {
                    width: 100%;
                    margin: 0 !important;
                    padding-left: 35px !important;

                    flex-direction: row-reverse;
                    justify-content: flex-end;

                    &.currentUser-active {
                        background-color: #f1f1f1;
                    }

                    .Icon-login {
                        margin-left: 8px;
                    }
                }

                .Nav--children--login {
                    width: 100%;

                    .Nav-list--child {
                        margin: 0;
                    }

                    .Nav-item {
                        // border: none;
                        margin: 0 !important;
                    }

                    .Nav-link {
                        padding-left: 55px !important;
                        background-color: $cc-grey--light;
                    }
                }
            }
        }

        .Nav-toggle {

            @include mq($until: $viewport--lg - 1) {

                .show-nav & {
                    color: $black;
                }
            }
        }

        .Nav-list {
            display: flex;
            align-items: center;

            @include mq($until: $viewport--lg - 1) {
                color: $white;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin-top: 155px;

                html:not(.show-nav) & {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        @include mq($until: $viewport--lg - 1) {
            .has-children {
                >.Nav-link {
                    &:nth-child(1) {
                        // border-bottom: 1px solid $cc-blue;
                        // display: inline-block;
                    }
                }
            }
        }

        .Nav-item {
            @include mq($until: $viewport--lg - 1) {
                text-align: center;
            }

            &.has-children {

                position: relative;

                @include mq($until: $viewport--lg - 1) {
                    margin-bottom: 10px;
                }

                @include mq($from: $viewport--lg) {
                    &:not(:hover) {
                        .Nav--children {
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }

                .Nav--children {
                    @include mq($from: $viewport--lg) {
                        &--main {
                            width: 260px;
                        }

                        position: absolute;
                        top: 101%;
                        left: calc(50% + 15px);
                        transition-property: opacity,
                        visibility;
                        transition-duration: .2s;
                        transition-timing-function: linear;
                        transform: translateX(-50%);
                        padding-top: 1.875rem;
                    }
                }

                .Nav-list--child {
                    @include mq($from: $viewport--lg) {
                        background-color: $cc-blue;
                        position: relative;
                        list-style: none;
                        text-align: center;
                    }

                    padding: 0;

                    .Nav-item {
                        @include mq($from: $viewport--lg) {
                            padding: 22px 30px;
                        }

                        // opacity: 1 !important;

                        &.is-active {
                            // opacity: 1 !important;
                        }
                    }

                    .Nav-link {
                        @include mq($from: $viewport--lg) {
                            color: $white;
                        }

                        // font-size: 14px;

                    }

                    @include mq($from: $viewport--lg) {
                        &::before {
                            border: 13px solid transparent;
                            height: 0;
                            width: 0;
                            border-top: none;
                            border-bottom-width: 12px;
                            border-bottom-color: $cc-blue;
                            position: absolute;
                            bottom: 100%;
                            left: 50%;
                            content: "";
                            transform: translateX(-50%);
                        }
                    }

                    .Nav-item {
                        &:not(:first-child) {
                            border-top: 1px solid rgba(255, 255, 255, .2);
                        }
                    }
                }
            }

            &--lang,
            &--login {
                @include mq ($from: $viewport--lg) {
                    border-left: 1px solid rgba(23, 23, 23, .4);
                    padding-left: u($spacing-unit) !important;
                    margin-left: u($spacing-unit) !important;
                }

                .Nav-link {
                    &:first-child {
                        @include mq ($until: $viewport--lg - 1) {
                            margin-right: 15px;
                        }
                    }
                }


                display: flex;
                align-items: center;

                .Icon {
                    &-down {
                        @include mq ($until: $viewport--lg - 1) {
                            display: none;
                        }

                        margin-left: 10px;
                    }

                    &-login {
                        margin-right: 8px;
                    }
                }
            }

            &--lang {
                .Nav-link {
                    @include mq ($until: $viewport--lg - 1) {
                        padding: 8px 12px;
                    }

                    border: none !important;

                }
            }

            @include mq($until: $viewport--lg - 1) {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }

                html:not(.show-nav) & {
                    opacity: 0;
                    transform: translateY(#{u($spacing-unit)});
                }

                +.Nav-item {
                    margin-top: u($spacing-unit--sm);
                }
            }

            &--hover {
                &.is-active {
                    opacity: 1 !important;
                }

                &:not(:hover) {
                    opacity: .4;
                }
            }




        }

        .Nav-link {
            font-family: $font-sofiaPro;
            font-weight: 600;
            letter-spacing: .9px;
            line-height: 18px;
            color: $cc-black;
            text-transform: uppercase;
            font-size: 13px;

            &:active,
            &:focus,
            &:hover {
                // color: currentColor;
                opacity: 1;
            }
        }

        @include mq($from: $viewport--lg) {

            .Nav-list {
                margin-left: u($spacing-unit--sm * -1);
            }

            .Nav-item {
                padding-left: u($spacing-unit * 1.5);
            }
        }
    }

    &--children {
        &--main {
            @include mq ($until: $viewport--lg - 1) {
                display: none;
            }
        }
    }

    // Footer
    &--footer {
        @include mq ($until: $viewport--sm - 1) {
            width: 100%;
        }
        .Nav-link {
            color: $cc-black;

            &:hover {
                text-decoration: underline;
            }
        }

        .Nav-list {
            @include mq ($until: $viewport--sm - 1) {
                justify-content: space-evenly;
                margin-bottom: 20px;
            }

            margin-left: u($spacing-unit--sm * -1);
        }

        .Nav-item {
            padding-left: u($spacing-unit / 1.2);


            &.FullWidth {
                @include mq ($until: $viewport--sm - 1) {
                    text-align: center;
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
    }

    //socialMedia
    &--socialMedia {
        margin-top: u($spacing-unit * 1.5);

        .Nav-item {
            margin-right: u($spacing-unit / 2);
        }

        .Nav-icon {
            circle {
                transition: fill .6s;
            }

            &:hover {
                circle {
                    fill: $cc-green--gradient;
                    transition: fill .3s;
                }
            }
        }
    }
}
