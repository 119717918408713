.Breadcrumbs {
    @include mq ($until: $viewport--md - 1){
        display: none;
    }
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    >ul {
        display: inline-flex;
        list-style: none;
        background-color: $white;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;

        position: relative;

        &::before {
            content: "";
            position: absolute;
            background-color: $white;
            height: 100%;

            @include mq($from: $viewport--xl) {
                left: calc(((100vw - #{$structure-width}px) / 2) * -1);
                width: calc((100vw - #{$structure-width}px) / 2);
            }

            @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
                left: -40px;
                width: 40px;
            }

            @include mq($until: $viewport--md - 1) {
                left: -20px;
                width: 20px;
            }
        }

    }



    &-item {
        display: flex;
        align-items: center;
        padding: 20px 10px;
        &:hover{
            .Breadcrumbs-link{
                text-decoration: none;
                cursor: pointer;
                opacity: 1;
            }
        }

        &:first-child {
            padding-left: 0 !important;
        }
        &:last-child{
            padding-right: 20px;
        }

        .icon-next {
            margin-left: 20px;
            width: 8px;
            height: 13px;
        }
    }

    &-link {
        text-transform: uppercase;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: .9px;
        color: $cc-black;
        opacity: .4;
        font-weight: 600;
        transition: opacity .6s, color .6s;
    }
}
