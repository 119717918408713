/* Icon */
.icon {

    &-category {
        * {
            fill: currentColor !important;
        }
    }



}
