/* Logo */
.Logo {
    flex-shrink: 0;
    margin-right: auto;
    @include mq ($until: $viewport--lg - 1){
        .show-nav &{
            svg{
                width: u(210px);
            }
        }
    }
    .headroom--not-top & {
        svg {
            width: u(210px);
            transition: width .6s;
        }
    }

    .Footer & {
        svg {
            width: u(210px);
        }
    }

    svg {
        display: block;
        @include mq ($until: $viewport--sm){}
        @include mq ($until: $viewport--sm){
            width: u(210px);
        }
        width: u(283px);
        transition: width .6s;
    }
}
