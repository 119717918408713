/* Cookie */
.CookieConsent {
    @include transition(#{transform, opacity, visibility}, 200ms, linear, #{0s, 200ms, 200ms});
    background-image: $cookie-background-color;
    color: $cookie-text-color;
    padding-top: u($spacing-unit--sm);
    padding-bottom: u($spacing-unit--sm);

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    font-size: 16px;


    &:not(.is-active) {
        transform: translateY(100%);
        opacity: 0;
        visibility: hidden;
    }

    > .Container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a {
        font-weight: 700;

        &,
        &:active,
        &:focus,
        &:hover {
            color: $cookie-link-color;
        }
    }

    &-close {
        cursor: pointer;
        display: block;
        flex-shrink: 0;
        font-size: 150%;
        margin-left: u($spacing-unit);
        line-height: 1;
        transition-property: opacity;
        transition-duration: 200ms;

        &:not(:hover) {
            opacity: .75;
        }
    }
}
