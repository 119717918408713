/* Structure */

// Container
.#{$structure-namespace}Container {
    margin-right: auto;
    margin-left: auto;
    max-width: u($structure-width + $structure-responsive-gutter--r + $structure-responsive-gutter--l);
    padding-right: u($structure-responsive-gutter--r);
    padding-left: u($structure-responsive-gutter--l);
    position: relative;
    width: 100%;

    &--xl {
        max-width: u($structure-width--xl + $structure-responsive-gutter--r + $structure-responsive-gutter--l);
    }

    &--lg {
        max-width: u($structure-width--lg + $structure-responsive-gutter--r + $structure-responsive-gutter--l);
    }

    &--md {
        max-width: u($structure-width--md + $structure-responsive-gutter--r + $structure-responsive-gutter--l);
    }

    &--sm {
        max-width: u($structure-width--sm + $structure-responsive-gutter--r + $structure-responsive-gutter--l);
    }

    &--xs {
        max-width: u($structure-width--xs + $structure-responsive-gutter--r + $structure-responsive-gutter--l);
    }

    // Responsive
    @include mq($from: $viewport--md) {
        max-width: u($structure-width + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
        padding-right: if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, u($structure-gutter--r), null);
        padding-left: if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, u($structure-gutter--l), null);

        &--xl {
            max-width: u($structure-width--xl + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
        }

        &--lg {
            max-width: u($structure-width--lg + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
        }

        &--md {
            max-width: u($structure-width--md + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
        }

        &--sm {
            max-width: u($structure-width--sm + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
        }

        &--xs {
            max-width: u($structure-width--xs + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
        }
    }
}

// Body
body {
    @include grid(100%, auto 1fr auto);
    grid-template-areas: "header" "main" "footer";
}

// Header
.#{$structure-namespace}Header {
    grid-area: header;
    height: if($structure-header-height != 'auto', u($structure-header-height), null);
    position: relative;

    @include mq($from: $viewport--md) {
        height: if($structure-header-height != 'auto', null, auto);
    }

    > .#{$structure-namespace}Container {
        // height: 100%;
    }
}

// Footer
.#{$structure-namespace}Footer {
    grid-area: footer;
    height: if($structure-footer-height != 'auto', u($structure-footer-height), null);
    position: relative;

    > .#{$structure-namespace}Container {
        height: 100%;
    }
}

// Main
.#{$structure-namespace}Main {
    grid-area: main;
    position: relative;

    > .#{$structure-namespace}Container {
        padding-top: u($structure-header-gutter);
        padding-bottom: u($structure-footer-gutter);

        @include mq($from: $viewport--md) {
            // height: 100%;
            grid-gap: u($structure-side-gutter);

            &.has-leftSide {
                @include grid(
                    if(is-number($structure-leftside-width), u($structure-leftside-width), $structure-leftside-width)
                    1fr,
                    auto
                );
                grid-template-areas: "left content";
            }

            &.has-rightSide {
                @include grid(
                    1fr
                    if(is-number($structure-rightside-width), u($structure-rightside-width), $structure-rightside-width),
                    auto
                );
                grid-template-areas: "content right";
            }

            &.has-sides {
                @include grid(
                    if(is-number($structure-leftside-width), u($structure-leftside-width), $structure-leftside-width)
                    auto
                    if(is-number($structure-rightside-width), u($structure-rightside-width), $structure-rightside-width),
                    auto
                );
                grid-template-areas: "left content right";
            }
        }
    }
}

// Sides
.#{$structure-namespace}Side {
    position: relative;

    // Responsive
    @include mq($until: $viewport--md - 1) {
        margin-top: u($spacing-unit);
    }

    @include mq($from: $viewport--md) {

        &--left {
            grid-area: left;
        }

        &--right {
            grid-area: right;
        }
    }
}

// Content
.#{$structure-namespace}Content {
    grid-area: content;
    position: relative;
}
