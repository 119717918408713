/* Button */

.Button {
    font-family: $font-sofiaPro;
    font-weight: 600;
    letter-spacing: .5px;
    text-transform: uppercase;


    @include transition(padding);
    display: inline-flex;
    align-items: center;
    padding: 13px 30px 13px 85px;


    &-icon {
        width: 35px;
        height: 13px;
        position: absolute;
        left: 30px;
        @include transition(left);
    }

    &:hover {
        // padding: 13px 85px 13px 30px;
        background-color: #318BA2;
        .Button-icon{
            // right: 30px;
            // left: calc(100% - 35px - 30px);
        }
    }

    // Primary
    &--primary {
        background-color: $cc-blue;
        @include transition(background-color);
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $white;

            }
        }

        &.Button--outline {
            color: $grey--300;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $grey--500;
            }
        }
    }

    // Secondary
    &--secondary {
        background-color: $cc-green;
        color: $white;
        @include transition(padding);
        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $white;
            }
        }

        &.Button--outline {
            color: $cyan--400;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $cyan--600;
            }
        }
    }

    // &--interview

    &--interview {
        background-color: $cc-green--gradient;
        color: $white;
        transition: .4s all;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &.is-active {
                color: $white;
            }

            &:hover{
                background: rgba($cc-green--gradient, .7);
                color: $white;
            }
        }
    }

    // Info
    &--info {
        background-color: $white;
        color: $black;
        .Button-icon{
            *{
                fill: $cc-black;
            }
        }

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {}
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $green--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $green--700;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $orange--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $red--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $red--700;
            }
        }
    }

    // Outline
    &--outline {
        background-color: transparent;
        border-color: currentColor;
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;
    }
}
