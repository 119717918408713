/* Mixin: Carret */
@mixin caret($caret-width, $border-width, $direction, $border-color, $background-color) {
    $width: $caret-with / 2;
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
    }

    @if $direction == top {
        &:before {
            top: 0;
            left: 0;
            border-bottom: $width solid $border-color;
            border-left: $width solid transparent;
            border-right: $width solid transparent;
        }

        &:after {
            left: $border-width;
            top: $border-width;
            border-bottom: ($width - $border-width) solid $background-color;
            border-left: ($width - $border-width) solid transparent;
            border-right: ($width - $border-width) solid transparent;
        }
    } @else if $direction == right {
        &:before {
            top: 0;
            left: 0;
            border-left: $width solid $border-color;
            border-top: $width solid transparent;
            border-bottom: $width solid transparent;
        }

        &:after {
            left: 0;
            top: $border-width;
            border-left: ($width - $border-width) solid $background-color;
            border-top: ($width - $border-width) solid transparent;
            border-bottom: ($width - $border-width) solid transparent;
        }
    } @else if $direction == bottom {
        &:before {
            top: 0;
            left: 0;
            border-top: $width solid $border-color;
            border-left: $width solid transparent;
            border-right: $width solid transparent;
        }

        &:after {
            left: $border-width;
            top: 0;
            border-top: ($width - $border-width) solid $background-color;
            border-left: ($width - $border-width) solid transparent;
            border-right: ($width - $border-width) solid transparent;
        }
    } @else if $direction == left {
        &:before {
            top: 0;
            left: 0;
            border-right: $width solid $border-color;
            border-top: $width solid transparent;
            border-bottom: $width solid transparent;
        }

        &:after {
            left: $border-width;
            top: $border-width;
            border-right: ($width - $border-width) solid $background-color;
            border-top: ($width - $border-width) solid transparent;
            border-bottom: ($width - $border-width) solid transparent;
        }
    }
}
