.CtaOverview {
    background-color: $white;

    &.Homepage {
        background-color: $cc-grey--light;
    }

    padding-top: u($spacing-unit * 4.25);
    padding-bottom: u($spacing-unit * 4.25);

    .CTA {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        background-color: $white;

        @include mq ($from: $viewport--lg) {
            display: flex;
            align-items: center;
        }

        &-media {
            @include mq($until: $viewport--md - 1) {
                display: none !important;
            }

            transform: scaleX(-1);

            @include mq ($from: $viewport--lg) {
                max-width: 620px;
            }

            &::before {
                @include mq($from: $viewport--lg) {
                    left: -8px;
                    width: calc(87% + 4px) !important;
                }

            }

            img {
                top: 5px;
            }
        }

        &-content {
            padding: 25px;

            // font-family: $font-azoSans;
            // font-weight: 300;
            @include mq ($from: $viewport--lg) {
                max-width: 540px;
                margin-right: auto;
                width: 80%;
                padding: 40px 40px 40px 80px;
            }
        }
    }
}
