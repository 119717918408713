/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-dosis:                           "Dosis", sans-serif !default; // 400, 700
$font-azoSans:                         "azo-sans-web", sans-serif !default; // 400, 700
$font-sofiaPro:                        "sofia-pro", sans-serif !default; // 400, 700
$font-poynter:                         "poynter-oldstyle-display", serif !default; // 400, 700

