/* Components: Button */

// Namespace
$button-namespace:                    $namespace !default;

// Padding
$button-padding--t:                   $spacing-unit / 3  !default;
$button-padding--r:                   $spacing-unit--sm !default;
$button-padding--b:                   $button-padding--t !default;
$button-padding--l:                   $button-padding--r !default;

// Typography
$button-font-size:                    $font-size !default;
$button-text-align:                   center !default;

// Borders
$button-border-width:                 0 !default;
$button-border-radius:                0 !default;

// Disabled
$button-disabled-opacity:             60% !default;
