.PagePage {
    background-color: $cc-grey--light;

    &.vacature {

        .Vacatures {
            background-color: $white;
            max-width: 840px;
            margin: 0 auto;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
            padding: 40px 20px;


            @include mq ($from: $viewport--md) {
                padding: 60px 60px;
            }

            @include mq ($from: $viewport--lg) {
                padding: 60px 120px;
            }

            &-item {
                position: relative;
                padding: 20px 0;

                &--info {
                    position: relative;
                }

                &.js-active {
                    .icon-next {
                        transform: rotate(90deg);
                    }
                }
                // &.js-active {
                //     .icon-next {
                //         transform: rotate(90deg);
                //     }

                //     .Vacatures-info {
                //         margin-top: 20px;
                //         max-height: 800px;
                //         opacity: 1;
                //         transition: opacity .8s, max-height .8s;
                //     }
                // }

                // &.js-active {
                //     .icon-next {
                //         transform: rotate(90deg);
                //     }

                //     .Vacatures-info {
                //         margin-top: 20px;
                //         max-height: 800px;
                //         opacity: 1;
                //         transition: opacity .8s, max-height .8s;
                //     }
                // }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    height: 2px;
                    width: 100%;
                    background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, .2) 0%);
                    background-position: bottom;
                    background-size: 6px 1px;
                    background-repeat: repeat-x;
                }

                &::before {
                    top: 0;
                }

                &::after {
                    display: none;
                }

                &.Last {
                    &::after {
                        display: block;
                        bottom: 0;
                    }
                }
            }

            &-title {
                margin: 0;
                font-size: 18px;
                line-height: 26px;
                font-weight: bold;
                color: $cc-black;

                .icon-next {
                    transition: transform .2s;
                }

                &:hover {
                    cursor: pointer;

                    .icon-next {
                        transform: rotate(90deg);
                    }
                }
            }

            &-info {

                display: none;
                // max-height: 0;
                // opacity: 0;
                // transition: opacity .8s, max-height .8s;


                .Contact {
                    padding: 20px 0;
                    background-color: $cc-blue;
                    text-align: center;

                    p {
                        font-size: 18px;
                        line-height: 26px;
                        opacity: .5;
                        color: $white;
                        margin-bottom: 10px;
                    }

                    &-item {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        opacity: 1 !important;

                        .icon-phone,
                        .icon-mail {
                            opacity: 1 !important;

                            * {
                                fill: $white !important;
                                opacity: 1 !important;
                            }
                        }
                    }

                    &-link {
                        color: $white;
                        font-size: 17px;
                        line-height: 18px;
                        letter-spacing: 1.13px;
                    }
                }
            }

        }
    }

    .ContentBlocks {
        max-width: 840px;
        margin: 80px auto;
        background-color: $white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        border-radius: 60px 0 0 0;
        padding-left: u($spacing-unit);
        padding-right: u($spacing-unit);

        @include mq ($from: $viewport--md) {
            padding-left: u($spacing-unit * 2);
            padding-right: u($spacing-unit * 2);
        }

        .ContentBlock {
            &.ContentBlock-left {
                .TitleBig {
                    margin-left: 0;
                }
            }

            .TitleBig {
                margin-left: 20px;

                &::before {
                    left: -40px !important;
                    width: 20px !important;

                    @include mq ($from: $viewport--md) {
                        left: -60px !important;
                        width: 40px !important;
                    }
                }
            }
        }


    }
}
