.Banner {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;

    .Banner-content {
        padding: 25px;
        font-family: $font-azoSans;
        font-weight: 300;

        p {
            font-size: 20px;
            letter-spacing: .5px;
            line-height: 30px;
        }
    }

    &.BannerHomepage {
        @include mq ($from: $viewport--lg) {
            margin-top: u($spacing-unit--xl);
        }


        @include mq ($until: $viewport--lg - 1) {
            border-radius: 60px 0 0 0;
        }

        @include mq ($from: $viewport--lg) {
            display: flex;
            align-items: center;
            flex-flow: unset !important;
        }

        .Media {

            @include mq($from: $viewport--lg) {
                max-width: 50%;
                width: 100%;
                border-radius: 60px 0 0 0;
                @supports(mask-image: url('../img/icons/icon-category.svg')) {
                    max-width: 729px;
                    width: 100%;
                    margin-top: auto;
                    border-radius: 0 0 0 0;
                }
            }

            @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {

                .Banner-media {
                    width: 100%;
                    height: 100%;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .Banner-content {


            @include mq($from: $viewport--lg) {
                max-width: 40%;
            }

            height: 100%;

            @include mq ($from: $viewport--xl) {
                width: 35%;
                padding: 40px 40px 40px 50px;
            }

            @include mq ($from: $viewport--lg, $until: $viewport--xl - 1) {
                width: 50%;
            }

            @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
                width: 100%;
            }

            .Nav--socialMedia {
                position: absolute;
                right: 15px;
                bottom: 15px;

                .Nav-item {
                    margin: 0;
                    margin-left: -10px;
                }

                .Nav-link {
                    &:hover {
                        .Nav-icon {
                            // transform: scale(1.2);

                            circle {
                                fill: transparent !important;
                            }

                            * {
                                fill: $cc-blue !important;
                            }
                        }
                    }
                }

                .Nav-icon {
                    width: 40px;
                    height: 35px;
                    transition: transform .3s;


                    circle {
                        fill: transparent !important;
                    }

                    * {
                        fill: $cc-grey !important;
                        transition: fill .3s;
                    }
                }
            }

            &--category {
                @include mq ($from: $viewport--sm) {
                    margin-left: 20px;
                }

                display: flex;
                margin-bottom: 16px;

                .icon-category {
                    margin-right: 13px;
                    width: 33px;
                    height: 19px;

                    * {
                        fill: currentColor;
                    }
                }

                h2 {
                    font-family: $font-sofiaPro;
                    font-size: 16px;
                    letter-spacing: .6px;
                    font-weight: 900;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
            }
        }
    }

    &.BannerNormal {
        border-radius: 60px 0 0 0;

        .Banner-media {
            position: relative;
            height: 100%;
            @include mq($from: $viewport--md) {
                min-height: 300px;
            }

            img {
                @include mq($from: $viewport--md) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                @include mq($until: $viewport--md - 1) {
                    max-height: 400px;
                }


                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 60px 0 0 0;
            }

            &::before {
                content: '';
                position: absolute;
                height: 10px;
                bottom: -10px;
                width: 100%;
                left: 0;
                background-image: $cc-gradient;
            }
        }

        .Banner-content {
            &.NoImage {
                padding-top: 55px !important;
                padding-bottom: 55px !important;

                @include mq($until: $viewport--md - 1) {
                    padding: 55px 30px;
                }

            }

            @include mq($from: $viewport--md) {
                padding: 40px 30px;

                .MultiColumn {
                    column-count: 2;
                }
            }

            @include mq($from: $viewport--lg) {
                padding: 55px 80px;
            }

            h3 {
                font-size: 20px;
                font-weight: 900;
                letter-spacing: 1.2px;
                color: $cc-black;
                text-transform: uppercase;
            }

            p {
                font-family: $font-sofiaPro;
                font-weight: 300;
                font-size: 14px;
                letter-spacing: normal;
                line-height: 1.43;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

}
