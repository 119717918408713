/* Colors: Custom */


$cc-pink:                          #e15a8c !default;
$cc-green--gradient:               #8dc541 !default;
$cc-grey--light:                   #f9f9f9 !default;
$cc-orange:                        #f6ab20 !default;
$cc-blue-gradient:                 #44bded !default;
$cc-black:                         #3a3a3a !default;
$cc-blue:                          #55c1dd !default;
$cc-green:                         #39b2a0 !default;
$cc-gradient:                      linear-gradient(to left, $cc-blue-gradient, $cc-green--gradient);


// Grey
$cc-grey:                           #ccc !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);
