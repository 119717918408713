.ForgotPass-fancybox {
    padding: 40px 20px;

    @include mq ($from: $viewport--md) {
        padding: 40px 60px;
    }
    h2 {
        font-family: $font-poynter;
        font-style: italic;
        color: $cc-black;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .Button {
        position: relative;
        width: 100%;
        @include mq($from: $viewport--sm) {
            width: calc(100% - 90px);
            margin-left: 90px;
        }
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.Login-fancybox {
    border-radius: 60px 0 0 0;
    max-width: 920px;
    width: 100%;
    padding: 40px 20px;

    @include mq ($from: $viewport--md) {
        padding: 40px 60px;
    }
    .ForgotPass{
        text-decoration: underline;
        color: $cc-black;
        opacity: .5;
        line-height: 1.07;
        font-style: italic;
        font-size: 15px;
    }
}

.Login {
    @include mq ($from: $viewport--lg) {
        display: flex;
        justify-content: space-between;

    }

    &-item {
        width: 100%;

        @include mq ($from: $viewport--lg) {
            max-width: 355px;
        }

        h2 {
            font-family: $font-poynter;
            color: $cc-black;
            font-style: italic;
            font-weight: bold;
            margin-bottom: 6px;
        }

        p {
            font-size: 15px;
            line-height: 16px;
            font-style: italic;
            color: $cc-black;
            opacity: .5;
            margin-bottom: 0;
        }

        .Form {
            margin-top: 30px;

            .Button {

                width: 100%;

                @include mq($from: $viewport--sm) {
                    width: calc(100% - 90px);
                    margin-left: 90px;
                }

                font-size: 12px;
                position: relative;

                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &-login {
        @include mq ($until: $viewport--lg - 1) {
            margin-bottom: u($spacing-unit * 2);
        }

        @include mq ($from: $viewport--lg) {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: -40px;
                right: -45px;
                height: calc(100% + 80px);
                width: 2px;
                background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
                background-position: right;
                background-size: 1px 5px;
                background-repeat: repeat-y;
            }
        }

    }

    &-register {}
}
