/* Objects: Structure */

// Namespace
$structure-namespace:                   $namespace !default;

// Widths & Heights
$structure-width:                        1200 !default;
$structure-width--xl:                    1500 !default;
$structure-width--lg:                    1340 !default;
$structure-width--md:                    $structure-width !default;
$structure-width--sm:                    1000 !default;
$structure-width--xs:                    660 !default;

$structure-gutter--l:                    40 !default;
$structure-gutter--r:                    $structure-gutter--l !default;

$structure-header-height:                auto !default;
$structure-header-gutter:                $spacing-unit !default;

$structure-footer-height:                auto !default;
$structure-footer-gutter:                $spacing-unit !default;

$structure-leftside-width:               100% / 3 !default;
$structure-rightside-width:              100% / 3 !default;
$structure-side-gutter:                  $spacing-unit--lg !default;

// Responsive
$structure-responsive-gutter--l:         20 !default;
$structure-responsive-gutter--r:         $structure-responsive-gutter--l !default;

$structure-responsive-breakpoint:        $structure-width + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, 0) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, 0) !default;
