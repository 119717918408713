.TeamOverviewPage {
    background-color: $cc-grey--light;
    .Team-banner{
        img{
            width: 100%;
        }
    }

    .Team-header {
        position: relative;

        @include mq($from: $viewport--md) {
            top: -50px;
        }

        @include mq($until: $viewport--md - 1) {
            top: 20px;
        }


        &.Crosses {
            &::before {
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><image width="10" height="10" xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cCA2PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJWaXN1YWwtRGVzaWduIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJIb21lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC4wMDAwMDAsIC0zMDUuMDAwMDAwKSIgZmlsbD0iI0Q4RDhEOCI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuMDAwMDAwLCAzMDUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjUiIHk9IjAiIHdpZHRoPSIyIiBoZWlnaHQ9IjEyIj48L3JlY3Q+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtNi4wMDAwMDApICIgeD0iNSIgeT0iMCIgd2lkdGg9IjIiIGhlaWdodD0iMTIiPjwvcmVjdD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=" /></svg>');
                content: '';
                position: absolute;
                width: 100%;
                top: 50px;
                height: 140px;
            }
        }

        >.Container {
            @include mq($from: $viewport--md) {
                display: flex;
            }

        }

        .Inleiding {
            background-color: $white;

            @include mq($from: $viewport--md) {
                max-width: 600px;
                margin-right: 40px;
            }

            width: 100%;
            border-radius: 60px 0 0 0;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

            .Content {

                padding: 40px 20px;

                @include mq($from: $viewport--lg) {
                    padding: 60px 80px;
                }

                @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                    padding: 40px 40px;
                }

                &-category {
                    display: flex;

                    .icon-category {
                        margin-right: 13px;
                    }
                }
            }

        }

        .Quote {
            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit * 4);
            }

            background-color: transparent;
            padding: 0 !important;
            height: 100%;
            margin-top: auto;
            margin-left: auto;
            margin-bottom: u($spacing-unit * 2);
            max-width: 520px;

            >.Container {
                padding: 20px 0;
                text-align: right;

                &:before,
                &::after {
                    left: calc(100% - 40px);
                }
            }
        }
    }

    .Overview,
    .Team-detail {
        .TitleBig {
            margin-left: 20px;
        }

        .Team-item {
            margin-bottom: u($spacing-unit);

            .Inner {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            &--media {
                display: block;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                position: relative;

                .Media-svg {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 150px;
                }

                .Media-object {
                    display: block;
                    width: 100%;
                }

                .Media-object,
                .Media-svg {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $cc-black;
                        opacity: 0;
                        transition: opacity .6s;
                    }
                }

                &:hover {

                    .Media-object,
                    .Media-svg {
                        &::before {
                            opacity: .8;
                        }
                    }

                    .HoverOverlay {
                        opacity: 1;
                    }
                }

                .Media-object {
                    margin-bottom: auto;
                }

                .HoverOverlay {
                    position: absolute;
                    opacity: 0;
                    transition: opacity .6s;
                    left: 0;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .Content {
            margin-top: auto;
            color: $cc-black;
            padding: 30px;
            border-top: 2px solid #f1f1f1;

            a {
                color: $cc-black;
            }

            .Categories {
                display: flex;
                flex-wrap: wrap;

                &-item {
                    width: 100%;


                    @include mq($from: $viewport--sm) {
                        max-width: 50%;
                    }

                    display: flex;

                    h2 {
                        margin-bottom: 10px;
                    }

                    .icon-category {
                        margin-right: 13px;
                    }
                }
            }

            &-Functie {
                font-weight: 300;
                font-size: 15px;
                line-height: 16px;
                opacity: .5;
                margin-bottom: 4px;
                font-style: italic;
            }

            &-Title {
                font-family: $font-poynter;
                font-style: italic;
                font-weight: bold;
                margin-bottom: 10px;
            }

            &-item {
                margin-bottom: 4px;

                &--info {
                    color: $cc-blue;
                    font-weight: bold;
                }

                strong {
                    margin-right: 20px;

                }
            }
        }
    }

    .Team-detail {
        border-radius: 60px 0 0 0;
        max-width: 920px;
        padding-bottom: 3px;
        overflow: visible;

        &.ImageTrue {
            width: 100%;
        }


        .Inhoud {
            height: 100%;
            width: 100%;

            @include mq($until: 919px) {

                display: flex;
                flex-direction: column-reverse;
            }
        }


        .Content {
            max-width: 440px;
            padding: 0;
            padding-bottom: 44px;
            border: none;
            z-index: 1;
        }

        .ImageMask {
            z-index: 0;
            img{
                width: 100%;
            }

            @include mq($until: 919px) {
                margin-bottom: u($spacing-unit * 2);
            }

            @include mq($from: 920px) {
                transform: translateY(-60%);
                margin-bottom: -30%;
                filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08));
                position: relative;
                bottom: -10px;
                right: -22px;
            }



            &-media {
                @include mq($from: 920px) {
                    mask-image: url('../img/icons/icon-category.svg');
                    mask-mode: alpha;
                    mask-repeat: no-repeat;
                    mask-size: 100%;

                    top: 3px;
                    margin-left: auto;


                    height: 410px;
                    width: 729px;
                    background-color: white;
                }

                .Media-object {
                    width: 100% !important;
                    height: 100% !important;
                    display: block !important;
                }

                position: relative;

                &::before {
                    position: absolute;
                    content: '';
                    height: 10px;

                    @include mq($until: 919px) {
                        bottom: 0;
                    }

                    @include mq($from: 920px) {
                        bottom: 5px;
                    }

                    left: 0;
                    width: 100%;
                    background-color: $cc-blue;
                }
            }

            &-img {
                @include mq($from: 920px) {
                    max-width: 70%;
                    margin-left: auto;
                    margin-top: auto;

                }

                object-fit: cover;
            }
        }
    }
}
