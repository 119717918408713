/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: Palette */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Components: Fields */
/* Components: Cookie */
/* Components: Modal */
/* Components: DigitalPulse */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: Units */
/* Functions: String */
/* Functions: SVG */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Carret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 0.875em;
  line-height: 1.42857;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* HR */
hr {
  margin-top: 20px;
  margin-bottom: 19px;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #55c1dd;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    color: #39b2a0;
    text-decoration: underline; }

/* List */
ol,
ul {
  margin-bottom: 20px;
  padding-left: 20px; }
  ol li,
  ul li {
    margin-bottom: 2px; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #000000;
  background-color: #f1f075; }

/* Heading */
h1, .Alfa,
h2, .Bravo,
h3, .Charlie,
h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  font-family: "azo-sans-web", sans-serif;
  font-weight: 500; }
  h1 small, .Alfa small,
  h2 small, .Bravo small,
  h3 small, .Charlie small,
  h4 small, .Delta small,
  h5 small, .Echo small,
  h6 small, .Foxtrot small {
    font-weight: 300; }

h1, .Alfa {
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 1.2; }

h2, .Bravo {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.2; }

h3, .Charlie {
  margin-bottom: 20px;
  font-size: 18px; }

h4, .Delta {
  margin-bottom: 20px;
  font-size: 16px; }

h5, .Echo {
  margin-bottom: 20px;
  font-size: 14px; }

h6, .Foxtrot {
  margin-bottom: 20px;
  font-size: 12px; }

.Giga {
  margin-bottom: 20px;
  font-size: 96px;
  line-height: 1.1; }

.Mega {
  margin-bottom: 20px;
  font-size: 72px;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 1.1; }

.Milli {
  margin-bottom: 20px;
  font-size: 10px; }

.Micro {
  margin-bottom: 20px;
  font-size: 8px; }

/* Paragraph */
p {
  margin-bottom: 20px; }

.Lead {
  font-size: 15.75px; }

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: "sofia-pro", sans-serif;
  height: 100%; }
  html.fonts-loaded {
    font-family: "sofia-pro", sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 20px;
  padding: 0.71429em 0.95238em 0.71429em 0.95238em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 26.66667px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 20px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 0px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.47619em 0.71429em 0.47619em 0.71429em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 10px; }
  @media screen and (min-width: 37.5em) {
    .Form-item:not(.Form-item--action) {
      display: flex; } }
  .Form-item:not(.Form-item--action).is-error {
    flex-wrap: wrap; }

.Form-label {
  display: inline-block;
  min-width: 90px;
  color: #3a3a3a;
  display: flex;
  align-items: center; }
  .is-required .Form-label::after {
    content: ' *'; }
    html[lang^="nl"] .is-required .Form-label::after {
      content: ' *'; }
    html[lang^="fr"] .is-required .Form-label::after {
      content: ' *'; }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 5px;
  color: #bdbdbd;
  font-size: 10.5px; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 10px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e0e0e0; }

.Form-help--validation {
  width: 100%; }
  .Form-item.is-error .Form-help--validation {
    color: #f44336; }
  .Form-item.is-success .Form-help--validation {
    color: #4caf50; }

.Form-controls {
  position: relative;
  width: 100%; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 5px;
    margin-bottom: 0;
    padding-right: 20px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -5px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 5px; }

.Grid--withSmallGutter {
  margin-left: -10px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 10px; }

.Grid--withGutter {
  margin-left: -20px; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 20px; }

.Grid--withLargeGutter {
  margin-left: -40px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 40px; }

.Grid--withExtraLargeGutter {
  margin-left: -80px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 80px; }

/* Icon */
.icon-category * {
  fill: currentColor !important; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto; }

.Nav-item {
  margin: 0; }

.Nav-link {
  user-select: none;
  display: block; }
  .Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
    text-decoration: none; }

.Nav-toggle {
  cursor: pointer;
  padding: 10px;
  margin-right: -10px; }
  .Nav-toggle .Toggle-icon {
    display: block;
    fill: currentColor;
    width: 25px;
    height: 25px; }
  .Nav-toggle path {
    transition-property: transform, opacity;
    transition-duration: 200ms;
    transition-timing-function: linear;
    transform: rotate(0deg); }
    .Nav-toggle path:nth-child(1) {
      transform-origin: 2px 6px; }
    .Nav-toggle path:nth-child(3) {
      transform-origin: 2px 12px; }
    .show-nav .Nav-toggle path:nth-child(1) {
      transform: rotate(45deg); }
    .show-nav .Nav-toggle path:nth-child(2) {
      opacity: 0; }
    .show-nav .Nav-toggle path:nth-child(3) {
      transform: rotate(-45deg); }

.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap; }

.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto; }

.Nav--alignCenter {
  text-align: center; }
  .Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: center; }

.Nav--alignRight {
  text-align: right; }
  .Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: flex-end; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1240px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xl {
    max-width: 1540px; }
  .Container--lg {
    max-width: 1380px; }
  .Container--md {
    max-width: 1240px; }
  .Container--sm {
    max-width: 1040px; }
  .Container--xs {
    max-width: 700px; }
  @media screen and (min-width: 48em) {
    .Container {
      max-width: 1280px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xl {
        max-width: 1580px; }
      .Container--lg {
        max-width: 1420px; }
      .Container--md {
        max-width: 1280px; }
      .Container--sm {
        max-width: 1080px; }
      .Container--xs {
        max-width: 740px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  grid-area: header;
  position: relative; }
  @media screen and (min-width: 48em) {
    .Header {
      height: auto; } }

.Footer {
  grid-area: footer;
  position: relative; }
  .Footer > .Container {
    height: 100%; }

.Main {
  grid-area: main;
  position: relative; }
  .Main > .Container {
    padding-top: 20px;
    padding-bottom: 20px; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 40px; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 20px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 3.33333px 6.66667px 3.33333px 6.66667px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 63.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -10px -10px 10px;
          padding: 10px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 63.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

/* Alert */
.Alert--success {
  color: white;
  background-color: #66b7cc;
  border-color: #55c1dd; }
  .Alert--success a {
    color: #bfbfbf; }

.Alert--info {
  color: #5690bf;
  background-color: #ebf4fb;
  border-color: #d7e9f7; }
  .Alert--info a {
    color: #396d96; }

.Alert--warning {
  color: #bf8c40;
  background-color: #fcf4e9;
  border-color: #f9ead3; }
  .Alert--warning a {
    color: #8f6930; }

.Alert--danger {
  color: #c56c66;
  background-color: #fceeed;
  border-color: #f8dbd9; }
  .Alert--danger a {
    color: #a2453e; }

/* Button */
.Button {
  font-family: "sofia-pro", sans-serif;
  font-weight: 600;
  letter-spacing: .5px;
  text-transform: uppercase;
  transition-property: padding;
  transition-duration: 200ms;
  transition-timing-function: linear;
  display: inline-flex;
  align-items: center;
  padding: 13px 30px 13px 85px; }
  .Button-icon {
    width: 35px;
    height: 13px;
    position: absolute;
    left: 30px;
    transition-property: left;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Button:hover {
    background-color: #318BA2; }
  .Button--primary {
    background-color: #55c1dd;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    color: #ffffff; }
    .Button--primary:not(.Button--outline):active, .Button--primary:not(.Button--outline):focus, .Button--primary:not(.Button--outline):hover, .Button--primary:not(.Button--outline).is-active {
      color: #ffffff; }
    .Button--primary.Button--outline {
      color: #e0e0e0; }
      .Button--primary.Button--outline:active, .Button--primary.Button--outline:focus, .Button--primary.Button--outline:hover, .Button--primary.Button--outline.is-active {
        color: #9e9e9e; }
  .Button--secondary {
    background-color: #39b2a0;
    color: #ffffff;
    transition-property: padding;
    transition-duration: 200ms;
    transition-timing-function: linear; }
    .Button--secondary:not(.Button--outline):active, .Button--secondary:not(.Button--outline):focus, .Button--secondary:not(.Button--outline):hover, .Button--secondary:not(.Button--outline).is-active {
      color: #ffffff; }
    .Button--secondary.Button--outline {
      color: #26c6da; }
      .Button--secondary.Button--outline:active, .Button--secondary.Button--outline:focus, .Button--secondary.Button--outline:hover, .Button--secondary.Button--outline.is-active {
        color: #00acc1; }
  .Button--interview {
    background-color: #8dc541;
    color: #ffffff;
    transition: .4s all; }
    .Button--interview:not(.Button--outline):active, .Button--interview:not(.Button--outline):focus, .Button--interview:not(.Button--outline).is-active {
      color: #ffffff; }
    .Button--interview:not(.Button--outline):hover {
      background: rgba(141, 197, 65, 0.7);
      color: #ffffff; }
  .Button--info {
    background-color: #ffffff;
    color: #000000; }
    .Button--info .Button-icon * {
      fill: #3a3a3a; }
    .Button--info.Button--outline {
      color: #2196f3; }
      .Button--info.Button--outline:active, .Button--info.Button--outline:focus, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-active {
        color: #1976d2; }
  .Button--success {
    background-color: #4caf50;
    color: #ffffff; }
    .Button--success:not(.Button--outline):active, .Button--success:not(.Button--outline):focus, .Button--success:not(.Button--outline):hover, .Button--success:not(.Button--outline).is-active {
      background-color: #388e3c;
      color: #ffffff; }
    .Button--success.Button--outline {
      color: #4caf50; }
      .Button--success.Button--outline:active, .Button--success.Button--outline:focus, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-active {
        color: #388e3c; }
  .Button--warning {
    background-color: #ff9800;
    color: #ffffff; }
    .Button--warning:not(.Button--outline):active, .Button--warning:not(.Button--outline):focus, .Button--warning:not(.Button--outline):hover, .Button--warning:not(.Button--outline).is-active {
      background-color: #f57c00;
      color: #ffffff; }
    .Button--warning.Button--outline {
      color: #ff9800; }
      .Button--warning.Button--outline:active, .Button--warning.Button--outline:focus, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-active {
        color: #f57c00; }
  .Button--danger {
    background-color: #f44336;
    color: #ffffff; }
    .Button--danger:not(.Button--outline):active, .Button--danger:not(.Button--outline):focus, .Button--danger:not(.Button--outline):hover, .Button--danger:not(.Button--outline).is-active {
      background-color: #d32f2f;
      color: #ffffff; }
    .Button--danger.Button--outline {
      color: #f44336; }
      .Button--danger.Button--outline:active, .Button--danger.Button--outline:focus, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-active {
        color: #d32f2f; }
  .Button--outline {
    background-color: transparent;
    border-color: currentColor; }
  .Button--large {
    font-size: 17.5px;
    padding-right: 20px;
    padding-left: 20px; }
  .Button--small {
    font-size: 10.5px;
    padding-right: 6.66667px;
    padding-left: 6.66667px; }
  .Button--full {
    display: block;
    width: 100%; }
  .Button--bare {
    border: 0;
    border-radius: 0;
    padding: 0; }

.Modal {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10; }
  .Modal, .Modal:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Modal::before {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    z-index: -1; }
  .Modal:not(.is-active) {
    opacity: 0;
    visibility: hidden; }
  .Modal-inner {
    display: flex;
    align-items: center;
    min-height: 100%; }
  .Modal-innerBody {
    background-color: #ffffff;
    border-radius: 0;
    border: 1px solid #ccc;
    border-color: #ccc;
    margin: 20px 20px 20px 20px;
    width: auto; }
    @media screen and (min-width: 37.5em) {
      .Modal-innerBody {
        margin: 40px auto;
        max-width: 500px;
        width: 100%; } }
  .Modal-header {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 10px 10px 10px; }
  .Modal-title {
    font-size: 20px;
    line-height: 1.2;
    line-height: 1.5;
    margin-bottom: 0; }
  .Modal-close {
    font-size: 21px;
    line-height: 1.2;
    transition-property: opacity;
    transition-duration: visibility;
    transition-timing-function: linear;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    padding: 10px 10px 10px 10px;
    margin: -10px -10px -10px auto; }
    html:not(.is-tabbing) .Modal-close:focus {
      outline: 0; }
    .Modal-close:not(:hover) {
      opacity: .5; }
  .Modal-body {
    padding: 0.71429em 0.71429em 0.71429em 0.71429em; }
  .Modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.71429em 0.71429em 0.71429em 0.71429em;
    border-top: 1px solid #e0e0e0; }

/* Outdated Browser */
.Outdated {
  background-color: #f44336;
  color: #ffffff;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500; }
  .Outdated-title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px; }
  .Outdated-text {
    margin-bottom: 0;
    line-height: 1; }
  .Outdated-button {
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    width: 230px;
    /*need for IE*/
    position: absolute;
    top: 12px;
    right: 24px; }
    .Outdated-button:hover {
      background-color: #ffffff;
      color: #f44336; }
  * html .Outdated {
    position: absolute; }

/* Cookie */
.CookieConsent {
  transition-property: transform, opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: 0s, 200ms, 200ms;
  background-image: linear-gradient(to left, #44bded, #8dc541);
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  font-size: 16px; }
  .CookieConsent:not(.is-active) {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden; }
  .CookieConsent > .Container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .CookieConsent a {
    font-weight: 700; }
    .CookieConsent a, .CookieConsent a:active, .CookieConsent a:focus, .CookieConsent a:hover {
      color: inherit; }
  .CookieConsent-close {
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    font-size: 150%;
    margin-left: 20px;
    line-height: 1;
    transition-property: opacity;
    transition-duration: 200ms; }
    .CookieConsent-close:not(:hover) {
      opacity: .75; }

/* Header */
.Header {
  z-index: 3;
  position: absolute;
  top: 0;
  width: 100%; }
  .Header .Header-main {
    padding-top: 0;
    padding-bottom: 0; }
    @supports (mask-image: url("../img/icons/icon-category.svg")) {
      .Header .Header-main {
        padding-top: 40px;
        padding-bottom: 40px; } }
  @media screen and (max-width: 63.9375em) {
    .show-nav .Header.headroom.headroom--not-top .Header-main {
      margin-top: 30px; }
      .show-nav .Header.headroom.headroom--not-top .Header-main .Logo {
        filter: grayscale(0%); } }
  @media screen and (max-width: 63.9375em) {
    .Header.headroom--not-top .Header-top {
      position: fixed;
      top: 0;
      width: 100%; } }
  .Header.headroom--not-top .Header-main {
    position: fixed;
    width: 100%;
    transition: padding .6s, box-shadow .6s, margin .6s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    top: 0;
    left: 0;
    right: 0; }
    @supports (mask-image: url("../img/icons/icon-category.svg")) {
      .Header.headroom--not-top .Header-main {
        padding-top: 20px;
        padding-bottom: 20px; } }
    .Header.headroom--not-top .Header-main .Logo {
      filter: grayscale(100%);
      transition: filter .6s; }
  .Header-top {
    background-image: linear-gradient(to left, #44bded, #8dc541);
    height: 30px;
    display: flex; }
    .Header-top > .Container {
      justify-content: flex-end; }
      .Header-top > .Container a {
        padding: 0 20px;
        position: relative;
        color: #ffffff;
        font-family: "sofia-pro", sans-serif;
        font-weight: 600;
        display: flex;
        height: 30px;
        align-items: center;
        border-left: 2px solid rgba(255, 255, 255, 0.4); }
        @media screen and (max-width: 23.75em) {
          .Header-top > .Container a {
            padding: 0 5px; } }
        .Header-top > .Container a:last-child {
          border-right: 2px solid rgba(255, 255, 255, 0.4); }
  .Header-main {
    position: absolute;
    top: 30px;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #ffffff;
    transition: padding .6s, box-shadow .6s; }
    .Header-main .Logo {
      filter: grayscale(0%);
      transition: filter .6s; }
  .Header .Container {
    display: flex;
    align-items: center; }
  @media screen and (max-width: 63.9375em) {
    .Header::after {
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: #ffffff;
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1; }
      html:not(.show-nav) .Header::after {
        opacity: 0;
        visibility: hidden; } }

/* Nav */
.Nav--main {
  margin-left: 20px; }
  @media screen and (max-width: 63.9375em) {
    .headroom--not-top .Nav--main .Nav-list {
      margin-top: 114px; }
    .Nav--main .Nav-item {
      width: 100%;
      border-top: 1px solid #f1f1f1;
      margin: 0 !important;
      text-align: left !important;
      list-style: none;
      opacity: 1 !important; }
      .Nav--main .Nav-item .Nav-link {
        padding: 15px 0 !important;
        display: flex;
        opacity: .8 !important;
        margin-left: 35px;
        cursor: pointer;
        align-items: center; }
        .Nav--main .Nav-item .Nav-link .icon-next {
          margin-left: 8px;
          transform: rotate(90deg);
          transition: transform .4s; }
          .Nav--main .Nav-item .Nav-link .icon-next * {
            fill: #000000;
            opacity: 1 !important; }
      .Nav--main .Nav-item.js--child-active {
        background-color: #f1f1f1; }
        .Nav--main .Nav-item.js--child-active .Nav-link .icon-next {
          transform: rotate(-90deg); }
        .Nav--main .Nav-item.js--child-active .Nav--children--main {
          display: block;
          background-color: #f9f9f9; }
          .Nav--main .Nav-item.js--child-active .Nav--children--main .Nav-item {
            opacity: 1 !important;
            border-top: 1px solid #f1f1f1 !important; }
            .Nav--main .Nav-item.js--child-active .Nav--children--main .Nav-item .Nav-link {
              margin-left: 55px !important;
              opacity: .4 !important; }
            .Nav--main .Nav-item.js--child-active .Nav--children--main .Nav-item.is-active a {
              color: #55c1dd !important;
              opacity: .8 !important; }
          .Nav--main .Nav-item.js--child-active .Nav--children--main .Nav-list--child {
            margin: 0; }
    .Nav--main .Nav-link {
      color: #000000 !important;
      line-height: 18px; }
    .Nav--main .Nav-item--lang {
      order: 1;
      display: flex;
      justify-content: center;
      background-color: #55c1dd; }
      .Nav--main .Nav-item--lang .Nav-link {
        width: 50%;
        justify-content: center;
        color: #ffffff !important;
        opacity: 1 !important;
        margin: 0; }
      .Nav--main .Nav-item--lang .Nav--children--lang {
        background-color: #f1f1f1;
        width: 50%; }
        .Nav--main .Nav-item--lang .Nav--children--lang .Nav-item {
          border: none; }
        .Nav--main .Nav-item--lang .Nav--children--lang .Nav-link {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: center;
          color: #3a3a3a !important; }
    .Nav--main .Nav-item--login {
      display: block !important; }
      .Nav--main .Nav-item--login .Nav-link {
        width: 100%;
        margin: 0 !important;
        padding-left: 35px !important;
        flex-direction: row-reverse;
        justify-content: flex-end; }
        .Nav--main .Nav-item--login .Nav-link.currentUser-active {
          background-color: #f1f1f1; }
        .Nav--main .Nav-item--login .Nav-link .Icon-login {
          margin-left: 8px; }
      .Nav--main .Nav-item--login .Nav--children--login {
        width: 100%; }
        .Nav--main .Nav-item--login .Nav--children--login .Nav-list--child {
          margin: 0; }
        .Nav--main .Nav-item--login .Nav--children--login .Nav-item {
          margin: 0 !important; }
        .Nav--main .Nav-item--login .Nav--children--login .Nav-link {
          padding-left: 55px !important;
          background-color: #f9f9f9; } }
  @media screen and (max-width: 63.9375em) {
    .show-nav .Nav--main .Nav-toggle {
      color: #000000; } }
  .Nav--main .Nav-list {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 63.9375em) {
      .Nav--main .Nav-list {
        color: #ffffff;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-top: 155px; }
        html:not(.show-nav) .Nav--main .Nav-list {
          opacity: 0;
          visibility: hidden; } }
  @media screen and (max-width: 63.9375em) {
    .Nav--main .Nav-item {
      text-align: center; } }
  .Nav--main .Nav-item.has-children {
    position: relative; }
    @media screen and (max-width: 63.9375em) {
      .Nav--main .Nav-item.has-children {
        margin-bottom: 10px; } }
    @media screen and (min-width: 64em) {
      .Nav--main .Nav-item.has-children:not(:hover) .Nav--children {
        opacity: 0;
        visibility: hidden; } }
    @media screen and (min-width: 64em) {
      .Nav--main .Nav-item.has-children .Nav--children {
        position: absolute;
        top: 101%;
        left: calc(50% + 15px);
        transition-property: opacity, visibility;
        transition-duration: .2s;
        transition-timing-function: linear;
        transform: translateX(-50%);
        padding-top: 1.875rem; }
        .Nav--main .Nav-item.has-children .Nav--children--main {
          width: 260px; } }
    .Nav--main .Nav-item.has-children .Nav-list--child {
      padding: 0; }
      @media screen and (min-width: 64em) {
        .Nav--main .Nav-item.has-children .Nav-list--child {
          background-color: #55c1dd;
          position: relative;
          list-style: none;
          text-align: center; } }
      @media screen and (min-width: 64em) {
        .Nav--main .Nav-item.has-children .Nav-list--child .Nav-item {
          padding: 22px 30px; } }
      @media screen and (min-width: 64em) {
        .Nav--main .Nav-item.has-children .Nav-list--child .Nav-link {
          color: #ffffff; } }
      @media screen and (min-width: 64em) {
        .Nav--main .Nav-item.has-children .Nav-list--child::before {
          border: 13px solid transparent;
          height: 0;
          width: 0;
          border-top: none;
          border-bottom-width: 12px;
          border-bottom-color: #55c1dd;
          position: absolute;
          bottom: 100%;
          left: 50%;
          content: "";
          transform: translateX(-50%); } }
      .Nav--main .Nav-item.has-children .Nav-list--child .Nav-item:not(:first-child) {
        border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .Nav--main .Nav-item--lang, .Nav--main .Nav-item--login {
    display: flex;
    align-items: center; }
    @media screen and (min-width: 64em) {
      .Nav--main .Nav-item--lang, .Nav--main .Nav-item--login {
        border-left: 1px solid rgba(23, 23, 23, 0.4);
        padding-left: 20px !important;
        margin-left: 20px !important; } }
    @media screen and (max-width: 63.9375em) {
      .Nav--main .Nav-item--lang .Nav-link:first-child, .Nav--main .Nav-item--login .Nav-link:first-child {
        margin-right: 15px; } }
    .Nav--main .Nav-item--lang .Icon-down, .Nav--main .Nav-item--login .Icon-down {
      margin-left: 10px; }
      @media screen and (max-width: 63.9375em) {
        .Nav--main .Nav-item--lang .Icon-down, .Nav--main .Nav-item--login .Icon-down {
          display: none; } }
    .Nav--main .Nav-item--lang .Icon-login, .Nav--main .Nav-item--login .Icon-login {
      margin-right: 8px; }
  .Nav--main .Nav-item--lang .Nav-link {
    border: none !important; }
    @media screen and (max-width: 63.9375em) {
      .Nav--main .Nav-item--lang .Nav-link {
        padding: 8px 12px; } }
  @media screen and (max-width: 63.9375em) {
    .Nav--main .Nav-item {
      transition-property: opacity, transform;
      transition-duration: 300ms, 1s;
      transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1); }
      .Nav--main .Nav-item:nth-child(1) {
        transition-delay: 0.02s; }
      .Nav--main .Nav-item:nth-child(2) {
        transition-delay: 0.04s; }
      .Nav--main .Nav-item:nth-child(3) {
        transition-delay: 0.06s; }
      .Nav--main .Nav-item:nth-child(4) {
        transition-delay: 0.08s; }
      .Nav--main .Nav-item:nth-child(5) {
        transition-delay: 0.1s; }
      .Nav--main .Nav-item:nth-child(6) {
        transition-delay: 0.12s; }
      .Nav--main .Nav-item:nth-child(7) {
        transition-delay: 0.14s; }
      .Nav--main .Nav-item:nth-child(8) {
        transition-delay: 0.16s; }
      .Nav--main .Nav-item:nth-child(9) {
        transition-delay: 0.18s; }
      .Nav--main .Nav-item:nth-child(10) {
        transition-delay: 0.2s; }
      html:not(.show-nav) .Nav--main .Nav-item {
        opacity: 0;
        transform: translateY(20px); }
      .Nav--main .Nav-item + .Nav-item {
        margin-top: 10px; } }
  .Nav--main .Nav-item--hover.is-active {
    opacity: 1 !important; }
  .Nav--main .Nav-item--hover:not(:hover) {
    opacity: .4; }
  .Nav--main .Nav-link {
    font-family: "sofia-pro", sans-serif;
    font-weight: 600;
    letter-spacing: .9px;
    line-height: 18px;
    color: #3a3a3a;
    text-transform: uppercase;
    font-size: 13px; }
    .Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover {
      opacity: 1; }
  @media screen and (min-width: 64em) {
    .Nav--main .Nav-list {
      margin-left: -10px; }
    .Nav--main .Nav-item {
      padding-left: 30px; } }

@media screen and (max-width: 63.9375em) {
  .Nav--children--main {
    display: none; } }

@media screen and (max-width: 37.4375em) {
  .Nav--footer {
    width: 100%; } }

.Nav--footer .Nav-link {
  color: #3a3a3a; }
  .Nav--footer .Nav-link:hover {
    text-decoration: underline; }

.Nav--footer .Nav-list {
  margin-left: -10px; }
  @media screen and (max-width: 37.4375em) {
    .Nav--footer .Nav-list {
      justify-content: space-evenly;
      margin-bottom: 20px; } }

.Nav--footer .Nav-item {
  padding-left: 16.66667px; }
  @media screen and (max-width: 37.4375em) {
    .Nav--footer .Nav-item.FullWidth {
      text-align: center;
      width: 100%;
      margin-bottom: 20px; } }

.Nav--socialMedia {
  margin-top: 30px; }
  .Nav--socialMedia .Nav-item {
    margin-right: 10px; }
  .Nav--socialMedia .Nav-icon circle {
    transition: fill .6s; }
  .Nav--socialMedia .Nav-icon:hover circle {
    fill: #8dc541;
    transition: fill .3s; }

/* Logo */
.Logo {
  flex-shrink: 0;
  margin-right: auto; }
  @media screen and (max-width: 63.9375em) {
    .show-nav .Logo svg {
      width: 210px; } }
  .headroom--not-top .Logo svg {
    width: 210px;
    transition: width .6s; }
  .Footer .Logo svg {
    width: 210px; }
  .Logo svg {
    display: block;
    width: 283px;
    transition: width .6s; }
    @media screen and (max-width: 37.5em) {
      .Logo svg {
        width: 210px; } }

/* Fields */
/* Fields: field */
.Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid #bdbdbd;
  color: #424242;
  vertical-align: middle;
  width: 100%; }
  @media screen and (min-width: 37.5em) {
    .Form-field--input,
    .Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
      max-width: 300px; } }
  .Form-item.is-error .Form-field--input,
  .Form-item.is-error .Form-field--textarea, .Form-item.is-error .Form-field--select {
    border-color: #f44336; }
  .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #4caf50; }

.Form-field--input,
.Form-field--textarea, .Form-field--select {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
  .Form-field--input:focus,
  .Form-field--textarea:focus, .Form-field--select:focus {
    border-color: #757575;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6;
    outline: 0;
    z-index: 1; }
    .Form-item.is-error .Form-field--input:focus,
    .Form-item.is-error .Form-field--textarea:focus, .Form-item.is-error .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
    .Form-item.is-success .Form-field--input:focus,
    .Form-item.is-success .Form-field--textarea:focus, .Form-item.is-success .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 60px !important; }

.Form-field--sm {
  max-width: 100px !important; }

.Form-field--md {
  max-width: 200px !important; }

.Form-field--lg {
  max-width: 300px !important; }

.Form-field--xl {
  max-width: 400px !important; }

.Form-field--full {
  max-width: none !important; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none; }
  .Form-field--radio p,
  .Form-field--checkbox p {
    margin: 0px; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 2.5px; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: #757575;
      border-color: #757575;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: 0 0 2px 1px #64b5f6; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.64286em 0.64286em;
    border: 1px solid #bdbdbd;
    flex-shrink: 0;
    height: 20px;
    margin-right: 10px;
    width: 20px;
    position: relative;
    top: 0.21429em; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  .Form-field--checkbox > .Form-indicator {
    border-radius: 0; }
  .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block; }
  .Form-field--file input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--file input[type='file']:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6; }
      .Form-item.is-error .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
      .Form-item.is-success .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }
  .Form-field--file .Form-indicator {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    border-radius: 0;
    border: 1px solid #bdbdbd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: #424242;
    display: block;
    line-height: normal;
    overflow: hidden;
    padding: 0.2381em 0.47619em 0.2381em 0.47619em;
    position: relative;
    white-space: nowrap;
    width: 100%; }
    .Form-field--file .Form-indicator::before {
      content: attr(data-file-text);
      display: block;
      padding-right: 100px; }
    .Form-field--file .Form-indicator::after {
      background-color: #eeeeee;
      border-left: 1px solid #bdbdbd;
      color: #757575;
      content: attr(data-file-button);
      padding: 0.2381em 0.47619em 0.2381em 0.47619em;
      text-align: center;
      width: 100px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0; }
  @media screen and (min-width: 37.5em) {
    .Form-field--file {
      max-width: 300px; } }

/* Fields: input */
.Form-field--input,
.Form-field--textarea {
  max-width: 100%;
  box-shadow: none;
  padding: 14px 23px;
  border: 1px solid rgba(58, 58, 58, 0.2);
  line-height: normal; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }
  @media screen and (min-width: 37.5em) {
    select {
      max-width: 300px; } }

.Form-field--select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20292.36%20402%22%3E%3Cpath%20d%3D%22M18.27%2C164.45H274.09a18.17%2C18.17%2C0%2C0%2C0%2C12.85-31.12L159%2C5.42a17.93%2C17.93%2C0%2C0%2C0-25.7%2C0L5.42%2C133.33a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7A17.57%2C17.57%2C0%2C0%2C0%2C18.27%2C164.45Z%22%2F%3E%3Cpath%20d%3D%22M274.09%2C237.55H18.27A17.57%2C17.57%2C0%2C0%2C0%2C5.42%2C243a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7l127.91%2C127.9a17.92%2C17.92%2C0%2C0%2C0%2C25.7%2C0L286.93%2C268.66a18.27%2C18.27%2C0%2C0%2C0-12.85-31.12Z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: normal;
  overflow: hidden;
  padding: 0.2381em 2.85714em 0.2381em 0.47619em; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.11905em 0.47619em 0.11905em 0.47619em; }
    .Form-field--select[multiple] option + option {
      border-top: 1px solid #bdbdbd; }

/* Slider */
/* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden; }
  .Slider--no-flexbox .Slider-item {
    float: left; }
  .Slider--vertical > .Slider-items {
    flex-direction: column; }
  .Slider-items {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform; }
  .Slider--android .Slider-item, .Slider-items {
    transform: translate3d(0px, 0, 0); }
  .Slider--multirow > .Slider-items {
    flex-wrap: wrap; }
  .Slider--free-mode > .Slider-items {
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .Slider-item {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    transition-property: transform; }
    .Slider-item.is-blank {
      visibility: hidden; }
  .Slider--autoheight,
  .Slider--autoheight .Slider-item {
    height: auto; }
  .Slider--autoheight .Slider-items {
    align-items: flex-start;
    transition-property: transform, height; }
  .Slider--3d {
    perspective: 1200px; }
    .Slider--3d .Slider-items,
    .Slider--3d .Slider-item,
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom,
    .Slider--3d .swiper-cube-shadow {
      transform-style: preserve-3d; }
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10; }
    .Slider--3d .Slider-item-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .Slider--wp8-horizontal,
  .Slider--wp8-horizontal > .Slider-items {
    touch-action: pan-y; }
  .Slider--wp8-vertical,
  .Slider--wp8-vertical > .Slider-items {
    touch-action: pan-x; }

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0; }

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out; }

.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity; }
  .Slider--fade .Slider-item .Slider-item {
    pointer-events: none; }

.Slider--fade .is-active,
.Slider--fade .is-active .is-active {
  pointer-events: auto; }

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px;
  /* Windows 8 IE 10 fix */ }

/* Slider: cube */
.Slider--cube {
  overflow: visible; }
  .Slider--cube .Slider-item {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    z-index: 1; }
    .Slider--cube .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--cube.Slider--rtl .Slider-item {
    transform-origin: 100% 0; }
  .Slider--cube .is-active,
  .Slider--cube .is-active .is-active {
    pointer-events: auto; }
  .Slider--cube .is-active,
  .Slider--cube .is-next,
  .Slider--cube .is-prev,
  .Slider--cube .is-next + .Slider-item {
    pointer-events: auto;
    visibility: visible; }
  .Slider--cube .swiper-slide-shadow-top,
  .Slider--cube .swiper-slide-shadow-bottom,
  .Slider--cube .swiper-slide-shadow-left,
  .Slider--cube .swiper-slide-shadow-right {
    backface-visibility: hidden;
    z-index: 0; }
  .Slider--cube .swiper-cube-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    filter: blur(50px);
    opacity: 0.6; }

/* Slider: flip */
.Slider--flip {
  overflow: visible; }
  .Slider--flip .Slider-item {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1; }
    .Slider--flip .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--flip .is-active,
  .Slider--flip .is-active .is-active {
    pointer-events: auto; }
  .Slider--flip .Slider-item--shadow-top,
  .Slider--flip .Slider-item--shadow-bottom,
  .Slider--flip .Slider-item--shadow-left,
  .Slider--flip .Slider-item--shadow-right {
    backface-visibility: hidden;
    z-index: 0; }

/* Slider: lazy */
.Slider-preloader {
  height: 42px;
  width: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -21px;
  margin-left: -21px; }
  .Slider-preloader:after {
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block; }

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* Slider: navigation */
.Slider-button {
  height: 44px;
  width: 27px;
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-top: -22px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 44px; }
  .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: none;
    display: none; }
  .Slider-button.is-locked {
    display: none; }

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
  right: auto;
  left: 10px; }

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
  right: 10px;
  left: auto; }

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0); }
  .Slider-pagination.is-hidden {
    opacity: 0; }
  .Slider-pagination.is-locked {
    display: none; }

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transform: scale(0.33);
    position: relative; }
  .Slider-pagination--bullets-dynamic .is-active {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-main {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-prev {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-prev-prev {
    transform: scale(0.33); }
  .Slider-pagination--bullets-dynamic .is-active-next {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-next-next {
    transform: scale(0.33); }

.Slider-pagination-item {
  height: 8px;
  width: 8px;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2; }
  button.Slider-pagination-item {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .is-clickable .Slider-pagination-item {
    cursor: pointer; }
  .Slider-pagination-item.is-active {
    background-color: currentColor;
    opacity: 1; }

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
    display: block;
    margin-right: 6px;
    margin-left: 6px; }
  .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
      display: inline-block;
      transition-property: transform, top;
      transition-duration: 200ms; }

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px; }

.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transition-property: transform, left;
    transition-duration: 200ms; }

.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms; }

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .Slider-pagination--progressbar .Slider-pagination-fill {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transform: scale(0);
    transform-origin: left top; }
  .Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
    transform-origin: right top; }
  .Slider--horizontal > .Slider-pagination--progressbar,
  .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 4px;
    width: 100%;
    left: 0;
    top: 0; }
  .Slider--vertical > .Slider-pagination--progressbar,
  .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 100%;
    width: 4px;
    left: 0;
    top: 0; }

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none; }
  .Slider--horizontal > .Slider-scrollbar {
    height: 5px;
    width: 98%;
    position: absolute;
    bottom: 3px;
    left: 1%;
    z-index: 50; }
  .Slider--vertical > .Slider-scrollbar {
    height: 98%;
    width: 5px;
    position: absolute;
    top: 1%;
    right: 3px;
    z-index: 50; }
  .Slider-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; }
  .Slider-scrollbar-cursor-drag {
    cursor: move; }
  .Slider-scrollbar .is-locked {
    display: none; }

/* Slider: thumbs */
/* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Slider--zoom > img,
  .Slider--zoom > svg,
  .Slider--zoom > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .Slider--zoom .is-zoomed {
    cursor: move; }

/* General */
.Main {
  margin-top: 164px;
  transition: margin .6s; }
  @media screen and (max-width: 37.5em) {
    .Main {
      margin-top: 155px; } }

@media screen and (max-width: 63.9375em) {
  .show-nav body {
    overflow: hidden; } }

.TitleBig {
  color: #3a3a3a !important;
  opacity: .3;
  margin-bottom: 25px;
  position: relative; }
  .TitleBig h4 {
    font-family: "azo-sans-web", sans-serif;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase; }
  .TitleBig::before {
    content: "";
    position: absolute;
    background-color: currentColor;
    height: 1px;
    top: 50%; }
    @media screen and (min-width: 80em) {
      .TitleBig::before {
        left: calc(((100vw - 1200px) / 2) * -1);
        width: calc(((100vw - 1200px) - 40px) / 2); } }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .TitleBig::before {
        left: -40px;
        width: 20px; } }
    @media screen and (max-width: 47.9375em) {
      .TitleBig::before {
        left: -20px;
        width: 10px; } }

.Title {
  display: flex;
  position: relative; }
  .Title::before {
    content: "";
    position: absolute;
    background-color: currentColor;
    height: 1px;
    top: 50%; }
    @media screen and (min-width: 80em) {
      .Title::before {
        left: calc(((100vw - 1200px) / 2) * -1);
        width: calc(((100vw - 1200px) - 40px) / 2); } }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .Title::before {
        left: -40px;
        width: 20px; } }
    @media screen and (max-width: 47.9375em) {
      .Title::before {
        left: -20px;
        width: 10px; } }
  .Title .icon-category {
    margin-right: 13px;
    width: 33px;
    height: 19px; }
    .Title .icon-category * {
      fill: currentColor; }
  .Title h4 {
    text-transform: uppercase;
    font-family: "sofia-pro", sans-serif;
    font-weight: 900;
    margin: 0; }

.Services {
  text-align: center; }
  @media screen and (min-width: 37.5em) {
    .Services {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  .Services-item {
    position: relative;
    font-weight: medium;
    color: #3a3a3a;
    opacity: .5;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 18px 12px;
    margin: 0 12px; }
    @media screen and (min-width: 37.5em) {
      .Services-item {
        padding: 0 12px; } }
    .Services-item::before, .Services-item::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ccc; }
      @media screen and (max-width: 37.4375em) {
        .Services-item::before, .Services-item::after {
          left: calc(50% - 4px);
          top: 0; } }
      @media screen and (min-width: 37.5em) {
        .Services-item::before, .Services-item::after {
          left: -16px;
          top: calc(50% - 4px); } }
    @media screen and (max-width: 37.4375em) {
      .Services-item::after {
        left: calc(50% - 4px);
        top: 100%; } }
    @media screen and (min-width: 37.5em) {
      .Services-item::after {
        right: -16px;
        left: calc(100% - -8px); } }

.svg-media {
  margin-top: auto;
  position: relative; }
  @supports (mask-image: url("../img/icons/icon-category.svg")) {
    @media screen and (min-width: 64em) {
      .svg-media {
        width: 100%;
        max-width: 729px;
        margin-bottom: -40px;
        top: -40px; } } }
  .svg-media::before {
    bottom: -10px;
    content: '';
    position: absolute;
    height: 10px;
    background-image: linear-gradient(to left, #44bded, #8dc541); }
    @media screen and (min-width: 64em) {
      .svg-media::before {
        width: calc(87% + 6px);
        left: -9px;
        clip-path: polygon(1.75% 0%, 100% 0%, 98% 100%, 0% 100%); } }
    @media screen and (max-width: 63.9375em) {
      .svg-media::before {
        width: 100%;
        left: 0; } }
  @supports (mask-image: url("../img/icons/icon-category.svg")) {
    @media screen and (min-width: 64em) {
      .svg-media--img {
        mask-image: url("../img/icons/icon-category.svg");
        mask-mode: alpha;
        mask-repeat: no-repeat;
        mask-size: 100%;
        position: relative;
        top: 3px; } } }
  @media screen and (max-width: 63.9375em) {
    .svg-media--img {
      height: 100%;
      object-fit: cover;
      border-radius: 60px 0 0 0; } }

.CrossBG {
  position: relative; }
  .CrossBG::before, .CrossBG::after {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><image width="10" height="10" xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cCA2PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJWaXN1YWwtRGVzaWduIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJIb21lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC4wMDAwMDAsIC0zMDUuMDAwMDAwKSIgZmlsbD0iI0Q4RDhEOCI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuMDAwMDAwLCAzMDUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjUiIHk9IjAiIHdpZHRoPSIyIiBoZWlnaHQ9IjEyIj48L3JlY3Q+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtNi4wMDAwMDApICIgeD0iNSIgeT0iMCIgd2lkdGg9IjIiIGhlaWdodD0iMTIiPjwvcmVjdD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=" /></svg>');
    content: '';
    position: absolute;
    top: 15%;
    height: 75%;
    width: 20px; }
    @media screen and (min-width: 80em) {
      .CrossBG::before, .CrossBG::after {
        width: calc(((100vw - 1200px) - 20px) / 2); } }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .CrossBG::before, .CrossBG::after {
        width: 40px; } }
  .CrossBG::before {
    left: -20px; }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .CrossBG::before {
        left: -40px; } }
    @media screen and (min-width: 80em) {
      .CrossBG::before {
        left: calc((((100vw - 1200px) - 20px) / 2) * -1); } }
  .CrossBG::after {
    right: -20px; }
    @media screen and (min-width: 48em) and (max-width: 79.9375em) {
      .CrossBG::after {
        right: -40px; } }
    @media screen and (min-width: 80em) {
      .CrossBG::after {
        right: calc((((100vw - 1200px) - 20px) / 2) * -1); } }

.Title-line {
  text-transform: uppercase;
  opacity: .3;
  color: #3a3a3a;
  letter-spacing: 1.5px;
  font-weight: 500;
  position: relative;
  margin-left: 80px;
  text-align: left; }
  .Title-line::before {
    position: absolute;
    content: "";
    height: 2px;
    width: 120px;
    background-color: rgba(58, 58, 58, 0.3);
    left: calc(-120px + -40px);
    top: calc(50% - 1px); }

.CategortiesOverview {
  background-color: #f9f9f9;
  padding-top: 85px;
  padding-bottom: 45px; }
  .CategortiesOverview .Categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .CategortiesOverview .Categorie {
    width: 100%;
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 30px 22px 30px;
    position: relative;
    margin-bottom: 40px; }
    @media screen and (min-width: 37.5em) {
      .CategortiesOverview .Categorie {
        max-width: calc((100% / 2) - 20px); } }
    @media screen and (min-width: 64em) {
      .CategortiesOverview .Categorie {
        max-width: calc((100% / 3) - 20px); } }
    .CategortiesOverview .Categorie-title {
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-weight: 900; }
    .CategortiesOverview .Categorie p,
    .CategortiesOverview .Categorie br,
    .CategortiesOverview .Categorie em,
    .CategortiesOverview .Categorie strong,
    .CategortiesOverview .Categorie ul,
    .CategortiesOverview .Categorie ol,
    .CategortiesOverview .Categorie li {
      color: #000000; }
    .CategortiesOverview .Categorie ul {
      margin-left: 15px; }
    .CategortiesOverview .Categorie p {
      margin-bottom: 10px !important; }
    .CategortiesOverview .Categorie .icon-category {
      position: absolute;
      width: 40px;
      height: 23px;
      left: 30px;
      top: -23px; }
    .CategortiesOverview .Categorie .Button {
      margin-top: auto; }

.NewsOverview {
  padding-top: 85px;
  padding-bottom: 45px;
  text-align: center; }
  .NewsOverview .newsItem {
    text-align: left;
    border-radius: 60px 0 0 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08); }
    @media screen and (min-width: 48em) {
      .NewsOverview .newsItem {
        display: flex;
        justify-content: space-between; } }
    .NewsOverview .newsItem-media {
      padding: 0 !important;
      position: relative; }
      @media screen and (min-width: 48em) {
        .NewsOverview .newsItem-media {
          width: 50%; } }
      .NewsOverview .newsItem-media::before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0x;
        height: 10px;
        bottom: -10px;
        background-image: linear-gradient(to left, #44bded, #8dc541); }
      .NewsOverview .newsItem-media img {
        border-radius: 60px 0 0 0;
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .NewsOverview .newsItem-content {
      padding-top: 40px;
      padding-bottom: 30px;
      padding-right: 20px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; }
      @media screen and (min-width: 48em) {
        .NewsOverview .newsItem-content {
          width: 50%;
          padding-right: 40px;
          padding-left: 40px; } }
      @media screen and (min-width: 64em) {
        .NewsOverview .newsItem-content {
          padding-right: 80px;
          padding-left: 80px; } }
    .NewsOverview .newsItem-title {
      text-transform: uppercase;
      letter-spacing: 1.2px;
      color: #55c1dd;
      font-weight: 900; }

.LazyLoad,
.LazyLoading {
  opacity: 0; }

.LazyLoaded {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  opacity: 1; }

/* Blocks */
/* Footer */
.Footer {
  position: relative;
  padding-top: 30px;
  background-color: #ffffff;
  text-align: left;
  overflow: hidden; }
  .Footer-title {
    text-transform: uppercase;
    font-family: "sofia-pro", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: .9px; }
  .Footer-link {
    color: #3a3a3a; }
    .Footer-link:hover {
      color: #3a3a3a; }
  .Footer-item {
    margin-bottom: 5px; }
    .Footer-item .Footer-link {
      margin-left: 15px; }
  @media screen and (max-width: 47.9375em) {
    .Footer .Grid-cell {
      margin-bottom: 20px; } }
  .Footer .Description p {
    max-width: 340px; }
  .Footer::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-image: linear-gradient(to left, #44bded, #8dc541); }
  .Footer-top {
    padding-top: 20px;
    padding-bottom: 73px; }
    .Footer-top .Grid-cell {
      margin-top: 30px; }
      .Footer-top .Grid-cell:first-child {
        margin-top: 0; }
  .Footer-bottom {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-top: 10px;
    padding-bottom: 36px; }
    @media screen and (min-width: 37.5em) {
      .Footer-bottom {
        align-items: center;
        justify-content: space-between; } }
    .Footer-bottom .icon-line {
      position: absolute;
      width: 1200px;
      top: -43px;
      left: 50%;
      margin-left: calc((1200px / 2) * -1); }
      @media screen and (max-width: 37.4375em) {
        .Footer-bottom .icon-line {
          top: -65px; } }
  .Footer-copyright {
    display: inline-flex;
    align-items: center;
    text-decoration: none; }
    .Footer-copyright, .Footer-copyright:active, .Footer-copyright:focus, .Footer-copyright:hover {
      color: currentColor; }
    .Footer-copyright svg {
      fill: currentColor;
      display: block;
      margin-left: 10px;
      height: 25px;
      width: 20px; }

/* DigitalPulse */
.DigitalPulse {
  color: currentColor;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em; }
  @media screen and (max-width: 37.4375em) {
    .DigitalPulse {
      margin: 0 auto !important; } }
  .DigitalPulse-icon, .DigitalPulse-body {
    display: block;
    fill: currentColor; }
  .DigitalPulse-label {
    margin-right: .3em;
    flex-shrink: 0; }
  .DigitalPulse-object {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .DigitalPulse-icon {
    flex-shrink: 0;
    height: 1.85743em;
    transform: translateY(5%);
    width: 1.5em; }
  @media screen and (min-width: 64em) {
    .DigitalPulse-object {
      transition-property: transform, right;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      min-width: 5.56167em;
      position: relative;
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(1.01) translateY(-50%); }
    .DigitalPulse:hover .DigitalPulse-body {
      margin-left: .3em; }
    .DigitalPulse:hover .DigitalPulse-object {
      color: currentColor;
      right: 100%; }
    .DigitalPulse-body {
      transition-property: width;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      margin-left: 0;
      transform: translateY(12.5%);
      display: flex;
      justify-content: flex-end;
      height: 0.94828em;
      width: 5.56167em; } }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 12pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 12pt;
    text-transform: none; }
  h1 {
    font-size: 24pt; }
  h2 {
    font-size: 18pt; }
  h3 {
    font-size: 14pt; } }

.Banner {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff; }
  .Banner .Banner-content {
    padding: 25px;
    font-family: "azo-sans-web", sans-serif;
    font-weight: 300; }
    .Banner .Banner-content p {
      font-size: 20px;
      letter-spacing: .5px;
      line-height: 30px; }
  @media screen and (min-width: 64em) {
    .Banner.BannerHomepage {
      margin-top: 80px; } }
  @media screen and (max-width: 63.9375em) {
    .Banner.BannerHomepage {
      border-radius: 60px 0 0 0; } }
  @media screen and (min-width: 64em) {
    .Banner.BannerHomepage {
      display: flex;
      align-items: center;
      flex-flow: unset !important; } }
  @media screen and (min-width: 64em) {
    .Banner.BannerHomepage .Media {
      max-width: 50%;
      width: 100%;
      border-radius: 60px 0 0 0; }
      @supports (mask-image: url("../img/icons/icon-category.svg")) {
        .Banner.BannerHomepage .Media {
          max-width: 729px;
          width: 100%;
          margin-top: auto;
          border-radius: 0 0 0 0; } } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Banner.BannerHomepage .Media .Banner-media {
      width: 100%;
      height: 100%; }
      .Banner.BannerHomepage .Media .Banner-media img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%; } }
  .Banner.BannerHomepage .Banner-content {
    height: 100%; }
    @media screen and (min-width: 64em) {
      .Banner.BannerHomepage .Banner-content {
        max-width: 40%; } }
    @media screen and (min-width: 80em) {
      .Banner.BannerHomepage .Banner-content {
        width: 35%;
        padding: 40px 40px 40px 50px; } }
    @media screen and (min-width: 64em) and (max-width: 79.9375em) {
      .Banner.BannerHomepage .Banner-content {
        width: 50%; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Banner.BannerHomepage .Banner-content {
        width: 100%; } }
    .Banner.BannerHomepage .Banner-content .Nav--socialMedia {
      position: absolute;
      right: 15px;
      bottom: 15px; }
      .Banner.BannerHomepage .Banner-content .Nav--socialMedia .Nav-item {
        margin: 0;
        margin-left: -10px; }
      .Banner.BannerHomepage .Banner-content .Nav--socialMedia .Nav-link:hover .Nav-icon circle {
        fill: transparent !important; }
      .Banner.BannerHomepage .Banner-content .Nav--socialMedia .Nav-link:hover .Nav-icon * {
        fill: #55c1dd !important; }
      .Banner.BannerHomepage .Banner-content .Nav--socialMedia .Nav-icon {
        width: 40px;
        height: 35px;
        transition: transform .3s; }
        .Banner.BannerHomepage .Banner-content .Nav--socialMedia .Nav-icon circle {
          fill: transparent !important; }
        .Banner.BannerHomepage .Banner-content .Nav--socialMedia .Nav-icon * {
          fill: #ccc !important;
          transition: fill .3s; }
    .Banner.BannerHomepage .Banner-content--category {
      display: flex;
      margin-bottom: 16px; }
      @media screen and (min-width: 37.5em) {
        .Banner.BannerHomepage .Banner-content--category {
          margin-left: 20px; } }
      .Banner.BannerHomepage .Banner-content--category .icon-category {
        margin-right: 13px;
        width: 33px;
        height: 19px; }
        .Banner.BannerHomepage .Banner-content--category .icon-category * {
          fill: currentColor; }
      .Banner.BannerHomepage .Banner-content--category h2 {
        font-family: "sofia-pro", sans-serif;
        font-size: 16px;
        letter-spacing: .6px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 0; }
  .Banner.BannerNormal {
    border-radius: 60px 0 0 0; }
    .Banner.BannerNormal .Banner-media {
      position: relative;
      height: 100%; }
      @media screen and (min-width: 48em) {
        .Banner.BannerNormal .Banner-media {
          min-height: 300px; } }
      .Banner.BannerNormal .Banner-media img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 60px 0 0 0; }
        @media screen and (min-width: 48em) {
          .Banner.BannerNormal .Banner-media img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; } }
        @media screen and (max-width: 47.9375em) {
          .Banner.BannerNormal .Banner-media img {
            max-height: 400px; } }
      .Banner.BannerNormal .Banner-media::before {
        content: '';
        position: absolute;
        height: 10px;
        bottom: -10px;
        width: 100%;
        left: 0;
        background-image: linear-gradient(to left, #44bded, #8dc541); }
    .Banner.BannerNormal .Banner-content.NoImage {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
      @media screen and (max-width: 47.9375em) {
        .Banner.BannerNormal .Banner-content.NoImage {
          padding: 55px 30px; } }
    @media screen and (min-width: 48em) {
      .Banner.BannerNormal .Banner-content {
        padding: 40px 30px; }
        .Banner.BannerNormal .Banner-content .MultiColumn {
          column-count: 2; } }
    @media screen and (min-width: 64em) {
      .Banner.BannerNormal .Banner-content {
        padding: 55px 80px; } }
    .Banner.BannerNormal .Banner-content h3 {
      font-size: 20px;
      font-weight: 900;
      letter-spacing: 1.2px;
      color: #3a3a3a;
      text-transform: uppercase; }
    .Banner.BannerNormal .Banner-content p {
      font-family: "sofia-pro", sans-serif;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: normal;
      line-height: 1.43; }
      .Banner.BannerNormal .Banner-content p:last-child {
        margin-bottom: 0; }

.TeamOverviewPage {
  background-color: #f9f9f9; }
  .TeamOverviewPage .Team-banner img {
    width: 100%; }
  .TeamOverviewPage .Team-header {
    position: relative; }
    @media screen and (min-width: 48em) {
      .TeamOverviewPage .Team-header {
        top: -50px; } }
    @media screen and (max-width: 47.9375em) {
      .TeamOverviewPage .Team-header {
        top: 20px; } }
    .TeamOverviewPage .Team-header.Crosses::before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><image width="10" height="10" xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cCA2PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJWaXN1YWwtRGVzaWduIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJIb21lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC4wMDAwMDAsIC0zMDUuMDAwMDAwKSIgZmlsbD0iI0Q4RDhEOCI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuMDAwMDAwLCAzMDUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjUiIHk9IjAiIHdpZHRoPSIyIiBoZWlnaHQ9IjEyIj48L3JlY3Q+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtNi4wMDAwMDApICIgeD0iNSIgeT0iMCIgd2lkdGg9IjIiIGhlaWdodD0iMTIiPjwvcmVjdD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=" /></svg>');
      content: '';
      position: absolute;
      width: 100%;
      top: 50px;
      height: 140px; }
    @media screen and (min-width: 48em) {
      .TeamOverviewPage .Team-header > .Container {
        display: flex; } }
    .TeamOverviewPage .Team-header .Inleiding {
      background-color: #ffffff;
      width: 100%;
      border-radius: 60px 0 0 0;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08); }
      @media screen and (min-width: 48em) {
        .TeamOverviewPage .Team-header .Inleiding {
          max-width: 600px;
          margin-right: 40px; } }
      .TeamOverviewPage .Team-header .Inleiding .Content {
        padding: 40px 20px; }
        @media screen and (min-width: 64em) {
          .TeamOverviewPage .Team-header .Inleiding .Content {
            padding: 60px 80px; } }
        @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
          .TeamOverviewPage .Team-header .Inleiding .Content {
            padding: 40px 40px; } }
        .TeamOverviewPage .Team-header .Inleiding .Content-category {
          display: flex; }
          .TeamOverviewPage .Team-header .Inleiding .Content-category .icon-category {
            margin-right: 13px; }
    .TeamOverviewPage .Team-header .Quote {
      background-color: transparent;
      padding: 0 !important;
      height: 100%;
      margin-top: auto;
      margin-left: auto;
      margin-bottom: 40px;
      max-width: 520px; }
      @media screen and (max-width: 47.9375em) {
        .TeamOverviewPage .Team-header .Quote {
          margin-top: 80px; } }
      .TeamOverviewPage .Team-header .Quote > .Container {
        padding: 20px 0;
        text-align: right; }
        .TeamOverviewPage .Team-header .Quote > .Container:before, .TeamOverviewPage .Team-header .Quote > .Container::after {
          left: calc(100% - 40px); }
  .TeamOverviewPage .Overview .TitleBig,
  .TeamOverviewPage .Team-detail .TitleBig {
    margin-left: 20px; }
  .TeamOverviewPage .Overview .Team-item,
  .TeamOverviewPage .Team-detail .Team-item {
    margin-bottom: 20px; }
    .TeamOverviewPage .Overview .Team-item .Inner,
    .TeamOverviewPage .Team-detail .Team-item .Inner {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      height: 100%; }
    .TeamOverviewPage .Overview .Team-item--media,
    .TeamOverviewPage .Team-detail .Team-item--media {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative; }
      .TeamOverviewPage .Overview .Team-item--media .Media-svg,
      .TeamOverviewPage .Team-detail .Team-item--media .Media-svg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px; }
      .TeamOverviewPage .Overview .Team-item--media .Media-object,
      .TeamOverviewPage .Team-detail .Team-item--media .Media-object {
        display: block;
        width: 100%; }
      .TeamOverviewPage .Overview .Team-item--media .Media-object,
      .TeamOverviewPage .Overview .Team-item--media .Media-svg,
      .TeamOverviewPage .Team-detail .Team-item--media .Media-object,
      .TeamOverviewPage .Team-detail .Team-item--media .Media-svg {
        position: relative; }
        .TeamOverviewPage .Overview .Team-item--media .Media-object::before,
        .TeamOverviewPage .Overview .Team-item--media .Media-svg::before,
        .TeamOverviewPage .Team-detail .Team-item--media .Media-object::before,
        .TeamOverviewPage .Team-detail .Team-item--media .Media-svg::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #3a3a3a;
          opacity: 0;
          transition: opacity .6s; }
      .TeamOverviewPage .Overview .Team-item--media:hover .Media-object::before,
      .TeamOverviewPage .Overview .Team-item--media:hover .Media-svg::before,
      .TeamOverviewPage .Team-detail .Team-item--media:hover .Media-object::before,
      .TeamOverviewPage .Team-detail .Team-item--media:hover .Media-svg::before {
        opacity: .8; }
      .TeamOverviewPage .Overview .Team-item--media:hover .HoverOverlay,
      .TeamOverviewPage .Team-detail .Team-item--media:hover .HoverOverlay {
        opacity: 1; }
      .TeamOverviewPage .Overview .Team-item--media .Media-object,
      .TeamOverviewPage .Team-detail .Team-item--media .Media-object {
        margin-bottom: auto; }
      .TeamOverviewPage .Overview .Team-item--media .HoverOverlay,
      .TeamOverviewPage .Team-detail .Team-item--media .HoverOverlay {
        position: absolute;
        opacity: 0;
        transition: opacity .6s;
        left: 0;
        width: 100%;
        text-align: center; }
  .TeamOverviewPage .Overview .Content,
  .TeamOverviewPage .Team-detail .Content {
    margin-top: auto;
    color: #3a3a3a;
    padding: 30px;
    border-top: 2px solid #f1f1f1; }
    .TeamOverviewPage .Overview .Content a,
    .TeamOverviewPage .Team-detail .Content a {
      color: #3a3a3a; }
    .TeamOverviewPage .Overview .Content .Categories,
    .TeamOverviewPage .Team-detail .Content .Categories {
      display: flex;
      flex-wrap: wrap; }
      .TeamOverviewPage .Overview .Content .Categories-item,
      .TeamOverviewPage .Team-detail .Content .Categories-item {
        width: 100%;
        display: flex; }
        @media screen and (min-width: 37.5em) {
          .TeamOverviewPage .Overview .Content .Categories-item,
          .TeamOverviewPage .Team-detail .Content .Categories-item {
            max-width: 50%; } }
        .TeamOverviewPage .Overview .Content .Categories-item h2,
        .TeamOverviewPage .Team-detail .Content .Categories-item h2 {
          margin-bottom: 10px; }
        .TeamOverviewPage .Overview .Content .Categories-item .icon-category,
        .TeamOverviewPage .Team-detail .Content .Categories-item .icon-category {
          margin-right: 13px; }
    .TeamOverviewPage .Overview .Content-Functie,
    .TeamOverviewPage .Team-detail .Content-Functie {
      font-weight: 300;
      font-size: 15px;
      line-height: 16px;
      opacity: .5;
      margin-bottom: 4px;
      font-style: italic; }
    .TeamOverviewPage .Overview .Content-Title,
    .TeamOverviewPage .Team-detail .Content-Title {
      font-family: "poynter-oldstyle-display", serif;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 10px; }
    .TeamOverviewPage .Overview .Content-item,
    .TeamOverviewPage .Team-detail .Content-item {
      margin-bottom: 4px; }
      .TeamOverviewPage .Overview .Content-item--info,
      .TeamOverviewPage .Team-detail .Content-item--info {
        color: #55c1dd;
        font-weight: bold; }
      .TeamOverviewPage .Overview .Content-item strong,
      .TeamOverviewPage .Team-detail .Content-item strong {
        margin-right: 20px; }
  .TeamOverviewPage .Team-detail {
    border-radius: 60px 0 0 0;
    max-width: 920px;
    padding-bottom: 3px;
    overflow: visible; }
    .TeamOverviewPage .Team-detail.ImageTrue {
      width: 100%; }
    .TeamOverviewPage .Team-detail .Inhoud {
      height: 100%;
      width: 100%; }
      @media screen and (max-width: 57.4375em) {
        .TeamOverviewPage .Team-detail .Inhoud {
          display: flex;
          flex-direction: column-reverse; } }
    .TeamOverviewPage .Team-detail .Content {
      max-width: 440px;
      padding: 0;
      padding-bottom: 44px;
      border: none;
      z-index: 1; }
    .TeamOverviewPage .Team-detail .ImageMask {
      z-index: 0; }
      .TeamOverviewPage .Team-detail .ImageMask img {
        width: 100%; }
      @media screen and (max-width: 57.4375em) {
        .TeamOverviewPage .Team-detail .ImageMask {
          margin-bottom: 40px; } }
      @media screen and (min-width: 57.5em) {
        .TeamOverviewPage .Team-detail .ImageMask {
          transform: translateY(-60%);
          margin-bottom: -30%;
          filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08));
          position: relative;
          bottom: -10px;
          right: -22px; } }
      .TeamOverviewPage .Team-detail .ImageMask-media {
        position: relative; }
        @media screen and (min-width: 57.5em) {
          .TeamOverviewPage .Team-detail .ImageMask-media {
            mask-image: url("../img/icons/icon-category.svg");
            mask-mode: alpha;
            mask-repeat: no-repeat;
            mask-size: 100%;
            top: 3px;
            margin-left: auto;
            height: 410px;
            width: 729px;
            background-color: white; } }
        .TeamOverviewPage .Team-detail .ImageMask-media .Media-object {
          width: 100% !important;
          height: 100% !important;
          display: block !important; }
        .TeamOverviewPage .Team-detail .ImageMask-media::before {
          position: absolute;
          content: '';
          height: 10px;
          left: 0;
          width: 100%;
          background-color: #55c1dd; }
          @media screen and (max-width: 57.4375em) {
            .TeamOverviewPage .Team-detail .ImageMask-media::before {
              bottom: 0; } }
          @media screen and (min-width: 57.5em) {
            .TeamOverviewPage .Team-detail .ImageMask-media::before {
              bottom: 5px; } }
      .TeamOverviewPage .Team-detail .ImageMask-img {
        object-fit: cover; }
        @media screen and (min-width: 57.5em) {
          .TeamOverviewPage .Team-detail .ImageMask-img {
            max-width: 70%;
            margin-left: auto;
            margin-top: auto; } }

.Form-indicator {
  top: 0 !important; }

.ContactPage {
  background-color: #f9f9f9; }
  .ContactPage .Quote {
    padding-bottom: 20px !important;
    background-color: #f9f9f9; }
    .ContactPage .Quote > .Container {
      max-width: 1200px;
      text-align: left; }
      .ContactPage .Quote > .Container h1 {
        max-width: 680px; }
      .ContactPage .Quote > .Container::before, .ContactPage .Quote > .Container::after {
        left: 0; }
  .ContactPage .Contact {
    padding-bottom: 60px !important; }
    @media screen and (min-width: 37.5em) {
      .ContactPage .Contact-item {
        margin-left: 60px; } }
    .ContactPage .Contact-item .icon {
      width: 19px;
      height: 19px;
      opacity: .2;
      margin-right: 19px; }
      .ContactPage .Contact-item .icon * {
        fill: #3a3a3a;
        opacity: 1; }
    .ContactPage .Contact-link {
      font-size: 17px;
      color: #3a3a3a;
      font-weight: 600; }
  .ContactPage .Formulier {
    background-color: #55c1dd;
    padding-top: 80px;
    padding-bottom: 80px; }
    .ContactPage .Formulier .TitleBig::before {
      background-color: #ffffff; }
    .ContactPage .Formulier .TitleBig h4 {
      color: #ffffff; }
    .ContactPage .Formulier > .Container {
      display: flex;
      flex-wrap: wrap; }
    .ContactPage .Formulier .Form {
      background-color: #ffffff;
      padding: 40px 20px;
      max-width: 680px;
      width: 100%; }
      @media screen and (min-width: 48em) {
        .ContactPage .Formulier .Form {
          margin-left: 40px;
          padding: 40px 80px; } }
      .ContactPage .Formulier .Form .Form-item.radio_group {
        background-color: #f9f9f9;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        position: relative; }
        .ContactPage .Formulier .Form .Form-item.radio_group .Form-controls {
          margin-top: 10px; }
        .ContactPage .Formulier .Form .Form-item.radio_group .Form-field--radio {
          margin-bottom: 10px; }
          .ContactPage .Formulier .Form .Form-item.radio_group .Form-field--radio:last-child {
            margin-bottom: 0 !important; }
        .ContactPage .Formulier .Form .Form-item.radio_group .Form-indicator {
          border-radius: 0;
          top: 0 !important; }
          .ContactPage .Formulier .Form .Form-item.radio_group .Form-indicator::after {
            display: none; }
        .ContactPage .Formulier .Form .Form-item.radio_group::before, .ContactPage .Formulier .Form .Form-item.radio_group::after {
          content: "";
          position: absolute;
          top: 0;
          height: 100%;
          width: 20px;
          background-color: #f9f9f9; }
          @media screen and (min-width: 48em) {
            .ContactPage .Formulier .Form .Form-item.radio_group::before, .ContactPage .Formulier .Form .Form-item.radio_group::after {
              width: 80px; } }
        .ContactPage .Formulier .Form .Form-item.radio_group::before {
          left: -20px; }
          @media screen and (min-width: 48em) {
            .ContactPage .Formulier .Form .Form-item.radio_group::before {
              left: -80px; } }
        .ContactPage .Formulier .Form .Form-item.radio_group::after {
          right: -20px; }
          @media screen and (min-width: 48em) {
            .ContactPage .Formulier .Form .Form-item.radio_group::after {
              right: -80px; } }
    .ContactPage .Formulier .Nav--socialMedia {
      margin-top: 0; }
      @media screen and (min-width: 48em) {
        .ContactPage .Formulier .Nav--socialMedia {
          margin-left: 60px; } }
      .ContactPage .Formulier .Nav--socialMedia--Title {
        color: #ffffff; }
      .ContactPage .Formulier .Nav--socialMedia .Nav-icon * {
        transition: opacity .4s; }
      .ContactPage .Formulier .Nav--socialMedia .Nav-icon:hover circle {
        fill: #ffffff;
        opacity: .5; }
      .ContactPage .Formulier .Nav--socialMedia .Nav-icon:hover * {
        fill: #55c1dd; }
      .ContactPage .Formulier .Nav--socialMedia .Nav-icon circle {
        fill: #ffffff; }
      .ContactPage .Formulier .Nav--socialMedia .Nav-icon * {
        fill: #55c1dd; }
  .ContactPage .CtaOverview {
    margin-top: 0 !important; }

.NewsPage {
  background-color: #f9f9f9;
  text-align: center; }
  .NewsPage ul {
    text-align: left; }
  .NewsPage .News {
    max-width: 840px;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 60px 0 0 0;
    margin-bottom: 40px; }
    .NewsPage .News-media {
      position: relative;
      margin-bottom: 50px; }
      .NewsPage .News-media::before {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 100%;
        height: 10px;
        background-image: linear-gradient(to left, #44bded, #8dc541); }
      .NewsPage .News-media img {
        border-radius: 60px 0 0 0;
        width: 100%; }
    .NewsPage .News-content {
      padding-top: 0 !important;
      padding: 20px;
      max-width: 550px;
      margin: 0 auto; }
      .NewsPage .News-content--date {
        display: block;
        font-size: 14px;
        color: #55c1dd;
        font-family: "sofia-pro", sans-serif;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 20px; }
      .NewsPage .News-content--title {
        color: #3a3a3a;
        opacity: .3;
        font-weight: 500;
        letter-spacing: 1.53px;
        margin-bottom: 25px; }
    .NewsPage .News .ContentBlocks {
      text-align: left;
      padding: 20px; }
      @media screen and (min-width: 48em) {
        .NewsPage .News .ContentBlocks {
          padding: 40px; } }

.InterviewPage {
  background-color: #f9f9f9; }
  .InterviewPage ul {
    text-align: left; }
  .InterviewPage .Interviews {
    max-width: 840px;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 60px 0 0 0;
    margin-bottom: 40px;
    overflow: hidden;
    color: #3a3a3a; }
    .InterviewPage .Interviews .Slider {
      border-radius: 60px 0 0 0; }
    .InterviewPage .Interviews .Slider-button--next {
      right: 30px;
      left: auto; }
    .InterviewPage .Interviews .Slider-button--prev {
      right: auto;
      left: 30px;
      transform: rotate(180deg); }
    .InterviewPage .Interviews-parentContainer {
      padding-top: 0;
      padding-bottom: 0; }
      @media screen and (max-width: 47.9375em) {
        .InterviewPage .Interviews-parentContainer {
          display: none; } }
    .InterviewPage .Interviews-backTopContainer {
      background: #ffffff;
      height: 58px;
      display: inline-flex;
      align-items: center;
      position: relative; }
      .InterviewPage .Interviews-backTopContainer::before {
        content: "";
        position: absolute;
        background-color: #ffffff;
        height: 58px;
        left: calc(((100vw - 1200px) / 2) * -1);
        width: calc((100vw - 1200px) / 2); }
        @media screen and (min-width: 48em) and (max-width: 79.9375em) {
          .InterviewPage .Interviews-backTopContainer::before {
            left: -40px;
            width: 40px; } }
        @media screen and (max-width: 47.9375em) {
          .InterviewPage .Interviews-backTopContainer::before {
            left: -20px;
            width: 20px; } }
      .InterviewPage .Interviews-backTopContainer a,
      .InterviewPage .Interviews-backTopContainer button, .InterviewPage .Interviews-backTopContainer-linkBank {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #3a3a3a;
        opacity: .4;
        padding-right: 30px;
        cursor: pointer; }
    .InterviewPage .Interviews-back {
      transform: rotate(180deg);
      margin-right: 17px; }
    .InterviewPage .Interviews-personImage {
      border: white 10px solid;
      border-radius: 100%; }
    .InterviewPage .Interviews-personImgContainer {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: white;
      border-radius: 100%; }
    .InterviewPage .Interviews-personInfo {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      padding-top: 15px;
      text-align: center; }
    .InterviewPage .Interviews-place {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      padding-top: 5px;
      text-align: center; }
    .InterviewPage .Interviews-logoContainer {
      max-width: 200px;
      background: #ffffff;
      position: absolute;
      z-index: 2;
      top: 80px;
      right: 15.5%;
      width: 200px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (max-width: 80em) {
        .InterviewPage .Interviews-logoContainer {
          right: 10%; } }
      @media screen and (max-width: 64em) {
        .InterviewPage .Interviews-logoContainer {
          right: 15px; } }
      @media screen and (max-width: 48em) {
        .InterviewPage .Interviews-logoContainer {
          right: 0; } }
    .InterviewPage .Interviews-media {
      position: relative;
      margin-bottom: 70px; }
      .InterviewPage .Interviews-media::before {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 100%;
        height: 10px;
        background-image: linear-gradient(to left, #44bded, #8dc541); }
      .InterviewPage .Interviews-media img {
        border-radius: 60px 0 0 0;
        width: 100%; }
    .InterviewPage .Interviews-content {
      padding-top: 40px;
      padding-bottom: 60px;
      max-width: 550px;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 48em) {
        .InterviewPage .Interviews-content {
          padding-bottom: 20px;
          padding-left: 20px;
          padding-right: 20px; } }
      .InterviewPage .Interviews-content--date {
        display: block;
        font-size: 14px;
        color: #55c1dd;
        font-family: "sofia-pro", sans-serif;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 20px; }
      .InterviewPage .Interviews-content--title {
        color: #55c1dd;
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.53px;
        text-transform: uppercase; }
    .InterviewPage .Interviews-titleQuote {
      padding-bottom: 21px; }
    .InterviewPage .Interviews-line {
      max-width: 155px;
      height: 1px;
      background: rgba(58, 58, 58, 0.3);
      transform: translate(-172px, 12px); }
      @media screen and (max-width: 37.5em) {
        .InterviewPage .Interviews-line {
          display: none; } }
    .InterviewPage .Interviews-intro p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal; }
    .InterviewPage .Interviews-intro p:last-of-type {
      margin-bottom: 0; }
    .InterviewPage .Interviews-text, .InterviewPage .Interviews-quote {
      padding-top: 20px;
      padding-bottom: 20px; }
    .InterviewPage .Interviews-title {
      padding-top: 20px; }
    .InterviewPage .Interviews-text {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal; }
      .InterviewPage .Interviews-text p:last-of-type {
        margin-bottom: 0; }
      .InterviewPage .Interviews-text--noPaddingT {
        padding-top: 0; }
    .InterviewPage .Interviews-title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .InterviewPage .Interviews-quoteText {
      font-family: "azo-sans-web", sans-serif;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.48px;
      color: #8dc541; }
    .InterviewPage .Interviews-quoteIconContainer {
      padding-bottom: 20px; }
    .InterviewPage .Interviews-quoteIcon * {
      fill: #8dc541; }
    .InterviewPage .Interviews-backContainer {
      text-align: center; }
    .InterviewPage .Interviews .ContentBlocks {
      text-align: left;
      padding: 20px; }
      @media screen and (min-width: 48em) {
        .InterviewPage .Interviews .ContentBlocks {
          padding: 40px; } }

.PagePage {
  background-color: #f9f9f9; }
  .PagePage.vacature .Vacatures {
    background-color: #ffffff;
    max-width: 840px;
    margin: 0 auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    padding: 40px 20px; }
    @media screen and (min-width: 48em) {
      .PagePage.vacature .Vacatures {
        padding: 60px 60px; } }
    @media screen and (min-width: 64em) {
      .PagePage.vacature .Vacatures {
        padding: 60px 120px; } }
    .PagePage.vacature .Vacatures-item {
      position: relative;
      padding: 20px 0; }
      .PagePage.vacature .Vacatures-item--info {
        position: relative; }
      .PagePage.vacature .Vacatures-item.js-active .icon-next {
        transform: rotate(90deg); }
      .PagePage.vacature .Vacatures-item::before, .PagePage.vacature .Vacatures-item::after {
        content: "";
        position: absolute;
        right: 0;
        height: 2px;
        width: 100%;
        background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0.2) 0%);
        background-position: bottom;
        background-size: 6px 1px;
        background-repeat: repeat-x; }
      .PagePage.vacature .Vacatures-item::before {
        top: 0; }
      .PagePage.vacature .Vacatures-item::after {
        display: none; }
      .PagePage.vacature .Vacatures-item.Last::after {
        display: block;
        bottom: 0; }
    .PagePage.vacature .Vacatures-title {
      margin: 0;
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
      color: #3a3a3a; }
      .PagePage.vacature .Vacatures-title .icon-next {
        transition: transform .2s; }
      .PagePage.vacature .Vacatures-title:hover {
        cursor: pointer; }
        .PagePage.vacature .Vacatures-title:hover .icon-next {
          transform: rotate(90deg); }
    .PagePage.vacature .Vacatures-info {
      display: none; }
      .PagePage.vacature .Vacatures-info .Contact {
        padding: 20px 0;
        background-color: #55c1dd;
        text-align: center; }
        .PagePage.vacature .Vacatures-info .Contact p {
          font-size: 18px;
          line-height: 26px;
          opacity: .5;
          color: #ffffff;
          margin-bottom: 10px; }
        .PagePage.vacature .Vacatures-info .Contact-item {
          margin-bottom: 10px;
          opacity: 1 !important; }
          .PagePage.vacature .Vacatures-info .Contact-item:last-child {
            margin-bottom: 0; }
          .PagePage.vacature .Vacatures-info .Contact-item .icon-phone,
          .PagePage.vacature .Vacatures-info .Contact-item .icon-mail {
            opacity: 1 !important; }
            .PagePage.vacature .Vacatures-info .Contact-item .icon-phone *,
            .PagePage.vacature .Vacatures-info .Contact-item .icon-mail * {
              fill: #ffffff !important;
              opacity: 1 !important; }
        .PagePage.vacature .Vacatures-info .Contact-link {
          color: #ffffff;
          font-size: 17px;
          line-height: 18px;
          letter-spacing: 1.13px; }
  .PagePage .ContentBlocks {
    max-width: 840px;
    margin: 80px auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 60px 0 0 0;
    padding-left: 20px;
    padding-right: 20px; }
    @media screen and (min-width: 48em) {
      .PagePage .ContentBlocks {
        padding-left: 40px;
        padding-right: 40px; } }
    .PagePage .ContentBlocks .ContentBlock.ContentBlock-left .TitleBig {
      margin-left: 0; }
    .PagePage .ContentBlocks .ContentBlock .TitleBig {
      margin-left: 20px; }
      .PagePage .ContentBlocks .ContentBlock .TitleBig::before {
        left: -40px !important;
        width: 20px !important; }
        @media screen and (min-width: 48em) {
          .PagePage .ContentBlocks .ContentBlock .TitleBig::before {
            left: -60px !important;
            width: 40px !important; } }

.Popup {
  max-width: 920px;
  width: 100%;
  padding: 40px 20px; }
  @media screen and (min-width: 45em) {
    .Popup {
      padding: 70px 60px 180px; } }
  .Popup-title {
    text-transform: uppercase;
    color: #55c1dd;
    font-weight: 900;
    margin-bottom: 10px; }
  .Popup-text {
    font-size: 20px;
    font-weight: 300; }
  .Popup-media {
    margin: 0 -20px;
    width: calc(100% + 40px);
    position: relative;
    bottom: -40px; }
    @media screen and (min-width: 45em) {
      .Popup-media {
        position: absolute;
        bottom: -3px;
        right: 0;
        width: 400px;
        height: 225px;
        margin: 0; } }
    @supports (mask-image: url("")) {
      @media screen and (min-width: 45em) {
        .Popup-media {
          mask-image: url("../img/icons/icon-category-alt.svg");
          mask-mode: alpha;
          mask-repeat: no-repeat;
          mask-position: 50% 100%;
          mask-size: 100%; } } }
    @media screen and (min-width: 45em) {
      .Popup-media picture {
        width: 100%;
        height: 100%; }
      .Popup-media img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%; } }

.fancybox-slide--html {
  padding: 20px; }
  @media screen and (min-width: 45em) {
    .fancybox-slide--html {
      padding: 40px; } }
  @media screen and (min-width: 50em) {
    .fancybox-slide--html {
      padding: 80px; } }

.fancybox-bg {
  background: rgba(0, 0, 0, 0.5); }

.Quote > .Container {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative; }
  .Quote > .Container::before, .Quote > .Container::after {
    position: absolute;
    content: "";
    width: 60px;
    height: 3px;
    background-color: #55c1dd;
    left: calc(50% - 30px); }
  .Quote > .Container::before {
    top: 0; }
  .Quote > .Container::after {
    bottom: 0; }
  .Quote > .Container h1 {
    font-family: "poynter-oldstyle-display", serif;
    font-size: 28px;
    font-style: italic;
    color: #55c1dd;
    margin-bottom: 10px; }

.ProductsPage .Info {
  background-color: #ffffff !important;
  padding-top: 80px;
  padding-bottom: 80px !important; }

.ProductsPage .RelatedProducts {
  padding-bottom: 80px; }

.CategoriesPage,
.ProductsPage,
.AccountPage,
.NewsOverviewPage {
  background-color: #f9f9f9; }
  .CategoriesPage.StandardPage .Info,
  .ProductsPage.StandardPage .Info,
  .AccountPage.StandardPage .Info,
  .NewsOverviewPage.StandardPage .Info {
    background-color: #ffffff; }
  .CategoriesPage .Products-item,
  .ProductsPage .Products-item,
  .AccountPage .Products-item,
  .NewsOverviewPage .Products-item {
    margin-bottom: 20px; }
    .CategoriesPage .Products-item .Inner,
    .ProductsPage .Products-item .Inner,
    .AccountPage .Products-item .Inner,
    .NewsOverviewPage .Products-item .Inner {
      background-color: #d8d8d8;
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 40px 0 0 0;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
      position: relative; }
      .CategoriesPage .Products-item .Inner:hover .Products-item--media::before, .CategoriesPage .Products-item .Inner:hover .Products-item--media::after,
      .ProductsPage .Products-item .Inner:hover .Products-item--media::before,
      .ProductsPage .Products-item .Inner:hover .Products-item--media::after,
      .AccountPage .Products-item .Inner:hover .Products-item--media::before,
      .AccountPage .Products-item .Inner:hover .Products-item--media::after,
      .NewsOverviewPage .Products-item .Inner:hover .Products-item--media::before,
      .NewsOverviewPage .Products-item .Inner:hover .Products-item--media::after {
        opacity: 1; }
      .CategoriesPage .Products-item .Inner:hover .Media-image,
      .CategoriesPage .Products-item .Inner:hover svg,
      .ProductsPage .Products-item .Inner:hover .Media-image,
      .ProductsPage .Products-item .Inner:hover svg,
      .AccountPage .Products-item .Inner:hover .Media-image,
      .AccountPage .Products-item .Inner:hover svg,
      .NewsOverviewPage .Products-item .Inner:hover .Media-image,
      .NewsOverviewPage .Products-item .Inner:hover svg {
        transform: scale(1.2); }
      .CategoriesPage .Products-item .Inner .Label,
      .ProductsPage .Products-item .Inner .Label,
      .AccountPage .Products-item .Inner .Label,
      .NewsOverviewPage .Products-item .Inner .Label {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: flex-end; }
        .CategoriesPage .Products-item .Inner .Label-item,
        .ProductsPage .Products-item .Inner .Label-item,
        .AccountPage .Products-item .Inner .Label-item,
        .NewsOverviewPage .Products-item .Inner .Label-item {
          border: 2px solid #ffffff;
          border-radius: 5px;
          color: #ffffff;
          padding: 2px 10px;
          font-weight: 600; }
          .CategoriesPage .Products-item .Inner .Label-item:last-child,
          .ProductsPage .Products-item .Inner .Label-item:last-child,
          .AccountPage .Products-item .Inner .Label-item:last-child,
          .NewsOverviewPage .Products-item .Inner .Label-item:last-child {
            margin-bottom: 0 !important; }
        .CategoriesPage .Products-item .Inner .Label-herstellingen,
        .ProductsPage .Products-item .Inner .Label-herstellingen,
        .AccountPage .Products-item .Inner .Label-herstellingen,
        .NewsOverviewPage .Products-item .Inner .Label-herstellingen {
          background-color: #8dc541;
          margin-bottom: 3px; }
        .CategoriesPage .Products-item .Inner .Label-refurbished,
        .ProductsPage .Products-item .Inner .Label-refurbished,
        .AccountPage .Products-item .Inner .Label-refurbished,
        .NewsOverviewPage .Products-item .Inner .Label-refurbished {
          background-color: #55c1dd;
          margin-bottom: 3px; }
        .CategoriesPage .Products-item .Inner .Label-news,
        .ProductsPage .Products-item .Inner .Label-news,
        .AccountPage .Products-item .Inner .Label-news,
        .NewsOverviewPage .Products-item .Inner .Label-news {
          background-color: #e15a8c;
          margin-bottom: 3px; }
      .CategoriesPage .Products-item .Inner:hover,
      .ProductsPage .Products-item .Inner:hover,
      .AccountPage .Products-item .Inner:hover,
      .NewsOverviewPage .Products-item .Inner:hover {
        text-decoration: none; }
    .CategoriesPage .Products-item--media,
    .ProductsPage .Products-item--media,
    .AccountPage .Products-item--media,
    .NewsOverviewPage .Products-item--media {
      height: 100%;
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px 0 0 0;
      overflow: hidden;
      position: relative;
      padding: 20px; }
      .CategoriesPage .Products-item--media::before, .CategoriesPage .Products-item--media::after,
      .ProductsPage .Products-item--media::before,
      .ProductsPage .Products-item--media::after,
      .AccountPage .Products-item--media::before,
      .AccountPage .Products-item--media::after,
      .NewsOverviewPage .Products-item--media::before,
      .NewsOverviewPage .Products-item--media::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .6s; }
      .CategoriesPage .Products-item--media::before,
      .ProductsPage .Products-item--media::before,
      .AccountPage .Products-item--media::before,
      .NewsOverviewPage .Products-item--media::before {
        background-color: rgba(58, 58, 58, 0.3);
        z-index: 1; }
      .CategoriesPage .Products-item--media::after,
      .ProductsPage .Products-item--media::after,
      .AccountPage .Products-item--media::after,
      .NewsOverviewPage .Products-item--media::after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzNXB4IiBoZWlnaHQ9IjE0cHgiIHZpZXdCb3g9IjAgMCAzNSAxNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iYnV0dG9uLWJsdWUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xOS4wMDAwMDAsIC0xMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC03Ij4gICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOS4wMDAwMDAsIDguMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMzAuNzA5NTgyMSw3LjcgTDI2LjgzMjkzODMsMy45MTA3OTg4OSBDMjYuMzg5MDkwMSwzLjQ3Nzk3MzY1IDI2LjM4OTA5MDEsMi43NjAwMDMxMyAyNi44MzI1MjA5LDIuMzI2NTc0NTUgQzI3LjI3Nzg1NzYsMS44OTEyODMwNSAyNy45OTQyMTI0LDEuODkwMTQwNDEgMjguNDMxOTQ2OSwyLjMyOTE1Njg2IEwzNC4wNTI2ODIsNy44MjM3Nzg2NyBDMzQuNTEzMjc3LDguMjU3NzYwNjQgMzQuNTEzMjc3LDguOTg1Mjk0NjMgMzQuMDU5Mjk2LDkuNDEzOTQyODMgTDI4LjQzNTgwNzIsMTQuOTEwNDY4MyBDMjcuOTk4OTM2OSwxNS4zNTM0NDc2IDI3LjI3OTEwMDUsMTUuMzUzNDQ3NiAyNi44MzI5MzgzLDE0LjkxNzM0ODcgQzI2LjM5MDEwNTUsMTQuNDg1NTEzNiAyNi4zOTAxMDU1LDEzLjc3MTcyODQgMjYuODMyODU0NSwxMy4zMjM5NTEzIEwzMC41NDczNTk1LDkuNyBMMSw5LjcgQzAuNDQ3NzE1MjUsOS43IDcuNDYwNjk4NzNlLTE0LDkuMjUyMjg0NzUgNy40NjA2OTg3M2UtMTQsOC43IEM3LjQ2MDY5ODczZS0xNCw4LjE0NzcxNTI1IDAuNDQ3NzE1MjUsNy43IDEsNy43IEwzMC43MDk1ODIxLDcuNyBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
        background-repeat: no-repeat;
        background-position: center;
        z-index: 2; }
      .CategoriesPage .Products-item--media img,
      .ProductsPage .Products-item--media img,
      .AccountPage .Products-item--media img,
      .NewsOverviewPage .Products-item--media img {
        width: 100%; }
      .CategoriesPage .Products-item--media svg,
      .CategoriesPage .Products-item--media img,
      .ProductsPage .Products-item--media svg,
      .ProductsPage .Products-item--media img,
      .AccountPage .Products-item--media svg,
      .AccountPage .Products-item--media img,
      .NewsOverviewPage .Products-item--media svg,
      .NewsOverviewPage .Products-item--media img {
        transition: transform .6s; }
    .CategoriesPage .Products-item--title,
    .ProductsPage .Products-item--title,
    .AccountPage .Products-item--title,
    .NewsOverviewPage .Products-item--title {
      background-color: #ffffff;
      padding: 10px 20px;
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #3a3a3a !important;
      position: relative;
      text-align: center; }
      .CategoriesPage .Products-item--title .Date,
      .ProductsPage .Products-item--title .Date,
      .AccountPage .Products-item--title .Date,
      .NewsOverviewPage .Products-item--title .Date {
        position: absolute;
        top: -30px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        left: 0;
        width: 100%;
        text-align: center; }
  .CategoriesPage .RelatedProducts,
  .ProductsPage .RelatedProducts,
  .AccountPage .RelatedProducts,
  .NewsOverviewPage .RelatedProducts {
    background-color: #ffffff; }
    .CategoriesPage .RelatedProducts .Title,
    .ProductsPage .RelatedProducts .Title,
    .AccountPage .RelatedProducts .Title,
    .NewsOverviewPage .RelatedProducts .Title {
      color: #3a3a3a !important;
      opacity: .3; }
      .CategoriesPage .RelatedProducts .Title h4,
      .ProductsPage .RelatedProducts .Title h4,
      .AccountPage .RelatedProducts .Title h4,
      .NewsOverviewPage .RelatedProducts .Title h4 {
        font-family: "azo-sans-web", sans-serif;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase; }
  .CategoriesPage .Sectors,
  .ProductsPage .Sectors,
  .AccountPage .Sectors,
  .NewsOverviewPage .Sectors {
    margin: 0 auto;
    max-width: 1100px; }
    .CategoriesPage .Sectors-title,
    .ProductsPage .Sectors-title,
    .AccountPage .Sectors-title,
    .NewsOverviewPage .Sectors-title {
      font-weight: 900;
      letter-spacing: 1.2px;
      text-transform: uppercase; }
    @media screen and (max-width: 27.4375em) {
      .CategoriesPage .Sectors .Column-4,
      .ProductsPage .Sectors .Column-4,
      .AccountPage .Sectors .Column-4,
      .NewsOverviewPage .Sectors .Column-4 {
        column-count: 1; } }
    @media screen and (min-width: 27.5em) {
      .CategoriesPage .Sectors .Column-4,
      .ProductsPage .Sectors .Column-4,
      .AccountPage .Sectors .Column-4,
      .NewsOverviewPage .Sectors .Column-4 {
        column-count: 2; } }
    @media screen and (min-width: 37.5em) {
      .CategoriesPage .Sectors .Column-4,
      .ProductsPage .Sectors .Column-4,
      .AccountPage .Sectors .Column-4,
      .NewsOverviewPage .Sectors .Column-4 {
        column-count: 3; } }
    @media screen and (min-width: 64em) {
      .CategoriesPage .Sectors .Column-4,
      .ProductsPage .Sectors .Column-4,
      .AccountPage .Sectors .Column-4,
      .NewsOverviewPage .Sectors .Column-4 {
        column-count: 4; } }
    @media screen and (max-width: 27.4375em) {
      .CategoriesPage .Sectors .Column-3,
      .ProductsPage .Sectors .Column-3,
      .AccountPage .Sectors .Column-3,
      .NewsOverviewPage .Sectors .Column-3 {
        column-count: 1; } }
    @media screen and (min-width: 27.5em) {
      .CategoriesPage .Sectors .Column-3,
      .ProductsPage .Sectors .Column-3,
      .AccountPage .Sectors .Column-3,
      .NewsOverviewPage .Sectors .Column-3 {
        column-count: 2; } }
    @media screen and (min-width: 37.5em) {
      .CategoriesPage .Sectors .Column-3,
      .ProductsPage .Sectors .Column-3,
      .AccountPage .Sectors .Column-3,
      .NewsOverviewPage .Sectors .Column-3 {
        column-count: 3; } }
    @media screen and (max-width: 27.4375em) {
      .CategoriesPage .Sectors .Column-2,
      .ProductsPage .Sectors .Column-2,
      .AccountPage .Sectors .Column-2,
      .NewsOverviewPage .Sectors .Column-2 {
        column-count: 1; } }
    @media screen and (min-width: 27.5em) {
      .CategoriesPage .Sectors .Column-2,
      .ProductsPage .Sectors .Column-2,
      .AccountPage .Sectors .Column-2,
      .NewsOverviewPage .Sectors .Column-2 {
        column-count: 2; } }
    .CategoriesPage .Sectors .Column-1,
    .ProductsPage .Sectors .Column-1,
    .AccountPage .Sectors .Column-1,
    .NewsOverviewPage .Sectors .Column-1 {
      column-count: 1; }
  .CategoriesPage .Brands,
  .ProductsPage .Brands,
  .AccountPage .Brands,
  .NewsOverviewPage .Brands {
    display: flex;
    align-items: center; }
    .CategoriesPage .Brands .Slider,
    .ProductsPage .Brands .Slider,
    .AccountPage .Brands .Slider,
    .NewsOverviewPage .Brands .Slider {
      margin: 0 20px;
      width: 100%; }
    .CategoriesPage .Brands-button.is-disabled,
    .ProductsPage .Brands-button.is-disabled,
    .AccountPage .Brands-button.is-disabled,
    .NewsOverviewPage .Brands-button.is-disabled {
      opacity: .2;
      transition: opacity .6s; }
    .CategoriesPage .Brands-button--next,
    .ProductsPage .Brands-button--next,
    .AccountPage .Brands-button--next,
    .NewsOverviewPage .Brands-button--next {
      transform: rotate(180deg); }
    .CategoriesPage .Brands-item,
    .ProductsPage .Brands-item,
    .AccountPage .Brands-item,
    .NewsOverviewPage .Brands-item {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center; }
      .CategoriesPage .Brands-item--noLink,
      .ProductsPage .Brands-item--noLink,
      .AccountPage .Brands-item--noLink,
      .NewsOverviewPage .Brands-item--noLink {
        pointer-events: none;
        cursor: default; }
      .CategoriesPage .Brands-item:hover .Brands-img,
      .ProductsPage .Brands-item:hover .Brands-img,
      .AccountPage .Brands-item:hover .Brands-img,
      .NewsOverviewPage .Brands-item:hover .Brands-img {
        filter: grayscale(0%);
        opacity: 1; }
    .CategoriesPage .Brands-img,
    .ProductsPage .Brands-img,
    .AccountPage .Brands-img,
    .NewsOverviewPage .Brands-img {
      filter: grayscale(100%);
      opacity: .3;
      transition: filter .6s, opacity .6s;
      max-width: 100px;
      max-height: 60px;
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .CategoriesPage .Info,
  .ProductsPage .Info,
  .AccountPage .Info,
  .NewsOverviewPage .Info {
    background-color: #f9f9f9;
    padding-bottom: 60px; }
    .CategoriesPage .Info-White,
    .ProductsPage .Info-White,
    .AccountPage .Info-White,
    .NewsOverviewPage .Info-White {
      background-color: #ffffff; }
    .CategoriesPage .Info .Content,
    .ProductsPage .Info .Content,
    .AccountPage .Info .Content,
    .NewsOverviewPage .Info .Content {
      background-color: #55c1dd;
      text-align: center;
      color: #ffffff;
      font-size: 18px;
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px; }
    @media screen and (min-width: 37.5em) {
      .CategoriesPage .Info .Contact,
      .ProductsPage .Info .Contact,
      .AccountPage .Info .Contact,
      .NewsOverviewPage .Info .Contact {
        display: flex;
        justify-content: center;
        align-items: center; } }
    .CategoriesPage .Info .Contact-item,
    .ProductsPage .Info .Contact-item,
    .AccountPage .Info .Contact-item,
    .NewsOverviewPage .Info .Contact-item {
      justify-content: center;
      align-items: center;
      display: flex;
      margin: 0; }
      @media screen and (max-width: 37.4375em) {
        .CategoriesPage .Info .Contact-item:nth-child(2),
        .ProductsPage .Info .Contact-item:nth-child(2),
        .AccountPage .Info .Contact-item:nth-child(2),
        .NewsOverviewPage .Info .Contact-item:nth-child(2) {
          margin-top: 10px; } }
      @media screen and (min-width: 37.5em) {
        .CategoriesPage .Info .Contact-item:nth-child(2),
        .ProductsPage .Info .Contact-item:nth-child(2),
        .AccountPage .Info .Contact-item:nth-child(2),
        .NewsOverviewPage .Info .Contact-item:nth-child(2) {
          margin-left: 60px;
          position: relative; }
          .CategoriesPage .Info .Contact-item:nth-child(2)::before,
          .ProductsPage .Info .Contact-item:nth-child(2)::before,
          .AccountPage .Info .Contact-item:nth-child(2)::before,
          .NewsOverviewPage .Info .Contact-item:nth-child(2)::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 2px;
            background-color: #ffffff;
            opacity: .4;
            left: -30px;
            top: 0; } }
      .CategoriesPage .Info .Contact-item .icon-phone,
      .CategoriesPage .Info .Contact-item .icon-mail,
      .ProductsPage .Info .Contact-item .icon-phone,
      .ProductsPage .Info .Contact-item .icon-mail,
      .AccountPage .Info .Contact-item .icon-phone,
      .AccountPage .Info .Contact-item .icon-mail,
      .NewsOverviewPage .Info .Contact-item .icon-phone,
      .NewsOverviewPage .Info .Contact-item .icon-mail {
        margin-right: 10px; }
        .CategoriesPage .Info .Contact-item .icon-phone *,
        .CategoriesPage .Info .Contact-item .icon-mail *,
        .ProductsPage .Info .Contact-item .icon-phone *,
        .ProductsPage .Info .Contact-item .icon-mail *,
        .AccountPage .Info .Contact-item .icon-phone *,
        .AccountPage .Info .Contact-item .icon-mail *,
        .NewsOverviewPage .Info .Contact-item .icon-phone *,
        .NewsOverviewPage .Info .Contact-item .icon-mail * {
          fill: #ffffff;
          opacity: 1; }
    .CategoriesPage .Info .Contact-link,
    .ProductsPage .Info .Contact-link,
    .AccountPage .Info .Contact-link,
    .NewsOverviewPage .Info .Contact-link {
      color: #ffffff; }
      .CategoriesPage .Info .Contact-link strong,
      .ProductsPage .Info .Contact-link strong,
      .AccountPage .Info .Contact-link strong,
      .NewsOverviewPage .Info .Contact-link strong {
        font-weight: 600; }

.InterviewsProduct .Slider-button--next {
  right: -45px;
  left: auto; }
  @media screen and (max-width: 64em) {
    .InterviewsProduct .Slider-button--next {
      right: 20px; } }

.InterviewsProduct .Slider-button--prev {
  right: auto;
  left: -45px;
  transform: rotate(180deg); }
  @media screen and (max-width: 64em) {
    .InterviewsProduct .Slider-button--prev {
      left: 20px; } }

.InterviewsProduct .Slider-item {
  padding-left: 2px;
  padding-right: 2px; }

.InterviewsProduct-content {
  max-width: 840px;
  margin: 0 auto;
  background: #ffffff;
  padding: 60px 82px;
  position: relative;
  z-index: 2; }
  @media screen and (max-width: 48em) {
    .InterviewsProduct-content {
      padding: 30px 60px; } }

.InterviewsProduct-BGcross {
  max-width: 840px;
  margin: 0 auto;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><image width="10" height="10" xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cCA2PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJWaXN1YWwtRGVzaWduIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJIb21lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC4wMDAwMDAsIC0zMDUuMDAwMDAwKSIgZmlsbD0iI0Q4RDhEOCI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQuMDAwMDAwLCAzMDUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjUiIHk9IjAiIHdpZHRoPSIyIiBoZWlnaHQ9IjEyIj48L3JlY3Q+ICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtNi4wMDAwMDApICIgeD0iNSIgeT0iMCIgd2lkdGg9IjIiIGhlaWdodD0iMTIiPjwvcmVjdD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=" /></svg>');
  position: absolute;
  bottom: -55px;
  right: 165px;
  height: 185px;
  width: 840px;
  z-index: 1;
  background-position-y: bottom; }

.InterviewsProduct-title {
  color: #55c1dd;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.53px;
  text-transform: uppercase;
  margin-bottom: 10px; }
  @media screen and (max-width: 48em) {
    .InterviewsProduct-title {
      font-size: 18px; } }

.InterviewsProduct-titleQuote {
  padding-bottom: 21px; }

.InterviewsProduct-personInfo {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal; }

.InterviewsProduct-place {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding-top: 5px; }

.InterviewsProduct-linkContainer {
  padding-top: 40px; }

.CtaOverview {
  background-color: #ffffff;
  padding-top: 85px;
  padding-bottom: 85px; }
  .CtaOverview.Homepage {
    background-color: #f9f9f9; }
  .CtaOverview .CTA {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff; }
    @media screen and (min-width: 64em) {
      .CtaOverview .CTA {
        display: flex;
        align-items: center; } }
    .CtaOverview .CTA-media {
      transform: scaleX(-1); }
      @media screen and (max-width: 47.9375em) {
        .CtaOverview .CTA-media {
          display: none !important; } }
      @media screen and (min-width: 64em) {
        .CtaOverview .CTA-media {
          max-width: 620px; } }
      @media screen and (min-width: 64em) {
        .CtaOverview .CTA-media::before {
          left: -8px;
          width: calc(87% + 4px) !important; } }
      .CtaOverview .CTA-media img {
        top: 5px; }
    .CtaOverview .CTA-content {
      padding: 25px; }
      @media screen and (min-width: 64em) {
        .CtaOverview .CTA-content {
          max-width: 540px;
          margin-right: auto;
          width: 80%;
          padding: 40px 40px 40px 80px; } }

.Breadcrumbs {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  @media screen and (max-width: 47.9375em) {
    .Breadcrumbs {
      display: none; } }
  .Breadcrumbs > ul {
    display: inline-flex;
    list-style: none;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    position: relative; }
    .Breadcrumbs > ul::before {
      content: "";
      position: absolute;
      background-color: #ffffff;
      height: 100%; }
      @media screen and (min-width: 80em) {
        .Breadcrumbs > ul::before {
          left: calc(((100vw - 1200px) / 2) * -1);
          width: calc((100vw - 1200px) / 2); } }
      @media screen and (min-width: 48em) and (max-width: 79.9375em) {
        .Breadcrumbs > ul::before {
          left: -40px;
          width: 40px; } }
      @media screen and (max-width: 47.9375em) {
        .Breadcrumbs > ul::before {
          left: -20px;
          width: 20px; } }
  .Breadcrumbs-item {
    display: flex;
    align-items: center;
    padding: 20px 10px; }
    .Breadcrumbs-item:hover .Breadcrumbs-link {
      text-decoration: none;
      cursor: pointer;
      opacity: 1; }
    .Breadcrumbs-item:first-child {
      padding-left: 0 !important; }
    .Breadcrumbs-item:last-child {
      padding-right: 20px; }
    .Breadcrumbs-item .icon-next {
      margin-left: 20px;
      width: 8px;
      height: 13px; }
  .Breadcrumbs-link {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .9px;
    color: #3a3a3a;
    opacity: .4;
    font-weight: 600;
    transition: opacity .6s, color .6s; }

.ContentBlocks {
  padding-top: 80px;
  padding-bottom: 80px; }
  .ContentBlocks .Content-title {
    text-transform: uppercase;
    color: #3a3a3a;
    opacity: .3;
    position: relative;
    margin-left: 20px; }
    .ContentBlocks .Content-title::before {
      content: "";
      position: absolute;
      height: 2px;
      top: 50%;
      background-color: #3a3a3a;
      opacity: .3;
      left: -40px;
      width: 20px; }
      @media screen and (min-width: 48em) {
        .ContentBlocks .Content-title::before {
          left: -60px;
          width: 40px; } }
  .ContentBlocks .ContentBlock-media {
    margin-top: auto;
    position: relative;
    margin-bottom: 10px;
    border-radius: 60px 0 0 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08); }
    .ContentBlocks .ContentBlock-media::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 10px;
      bottom: -10px;
      left: 0;
      background-image: linear-gradient(to left, #44bded, #8dc541); }
    .ContentBlocks .ContentBlock-media img {
      border-radius: 60px 0 0 0; }
  .ContentBlocks .ContentBlock-left {
    flex-direction: row-reverse; }
    @media screen and (min-width: 64em) {
      .ContentBlocks .ContentBlock-left .TitleBig::before {
        display: none; } }
  .ContentBlocks .ContentBlock p em {
    opacity: .5; }
  @media screen and (min-width: 64em) {
    .ContentBlocks .ContentBlock .Grid-cell:first-child {
      margin-top: 30px; } }
  @media screen and (min-width: 64em) {
    .ContentBlocks .ContentBlock .ContentBlock-media {
      height: 100%; }
      .ContentBlocks .ContentBlock .ContentBlock-media img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        height: 100%;
        width: 100%; } }
  .ContentBlocks .ContentBlock .Grid-cell {
    display: flex;
    flex-direction: column; }
    .ContentBlocks .ContentBlock .Grid-cell:first-child {
      align-items: flex-start; }
      @media screen and (max-width: 63.9375em) {
        .ContentBlocks .ContentBlock .Grid-cell:first-child {
          margin-bottom: 20px; } }

.ForgotPass-fancybox {
  padding: 40px 20px; }
  @media screen and (min-width: 48em) {
    .ForgotPass-fancybox {
      padding: 40px 60px; } }
  .ForgotPass-fancybox h2 {
    font-family: "poynter-oldstyle-display", serif;
    font-style: italic;
    color: #3a3a3a;
    font-weight: bold;
    margin-bottom: 20px; }
  .ForgotPass-fancybox .Button {
    position: relative;
    width: 100%; }
    @media screen and (min-width: 37.5em) {
      .ForgotPass-fancybox .Button {
        width: calc(100% - 90px);
        margin-left: 90px; } }
    .ForgotPass-fancybox .Button input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
      .ForgotPass-fancybox .Button input:hover {
        cursor: pointer; }

.Login-fancybox {
  border-radius: 60px 0 0 0;
  max-width: 920px;
  width: 100%;
  padding: 40px 20px; }
  @media screen and (min-width: 48em) {
    .Login-fancybox {
      padding: 40px 60px; } }
  .Login-fancybox .ForgotPass {
    text-decoration: underline;
    color: #3a3a3a;
    opacity: .5;
    line-height: 1.07;
    font-style: italic;
    font-size: 15px; }

@media screen and (min-width: 64em) {
  .Login {
    display: flex;
    justify-content: space-between; } }

.Login-item {
  width: 100%; }
  @media screen and (min-width: 64em) {
    .Login-item {
      max-width: 355px; } }
  .Login-item h2 {
    font-family: "poynter-oldstyle-display", serif;
    color: #3a3a3a;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 6px; }
  .Login-item p {
    font-size: 15px;
    line-height: 16px;
    font-style: italic;
    color: #3a3a3a;
    opacity: .5;
    margin-bottom: 0; }
  .Login-item .Form {
    margin-top: 30px; }
    .Login-item .Form .Button {
      width: 100%;
      font-size: 12px;
      position: relative; }
      @media screen and (min-width: 37.5em) {
        .Login-item .Form .Button {
          width: calc(100% - 90px);
          margin-left: 90px; } }
      .Login-item .Form .Button input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0; }
        .Login-item .Form .Button input:hover {
          cursor: pointer; }

@media screen and (max-width: 63.9375em) {
  .Login-login {
    margin-bottom: 40px; } }

@media screen and (min-width: 64em) {
  .Login-login {
    position: relative; }
    .Login-login::before {
      content: "";
      position: absolute;
      top: -40px;
      right: -45px;
      height: calc(100% + 80px);
      width: 2px;
      background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 5px;
      background-repeat: repeat-y; } }
