.NewsPage {
    background-color: $cc-grey--light;
    text-align: center;

    ul {
        text-align: left;
    }

    .News {
        max-width: 840px;
        margin: 0 auto;
        background-color: $white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        border-radius: 60px 0 0 0;
        margin-bottom: u($spacing-unit * 2);

        &-media {
            position: relative;
            margin-bottom: 50px;

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                width: 100%;
                height: 10px;
                background-image: $cc-gradient;
            }

            img {
                border-radius: 60px 0 0 0;
                width: 100%;
            }
        }

        &-content {
            padding-top: 0 !important;
            padding: 20px;
            max-width: 550px;
            margin: 0 auto;

            &--date {
                display: block;
                font-size: 14px;
                color: $cc-blue;
                font-family: $font-sofiaPro;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: u($spacing-unit);
            }

            &--title {
                color: $cc-black;
                opacity: .3;
                font-weight: 500;
                letter-spacing: 1.53px;
                margin-bottom: 25px;
            }
        }

        .ContentBlocks {
            text-align: left;
            padding: u($spacing-unit);

            @include mq ($from: $viewport--md) {
                padding: u($spacing-unit * 2);
            }
        }
    }
}
