/* Header */
.Header {
    z-index: 3;
    position: absolute;
    top: 0;
    width: 100%;

    .Header-main {
        padding-top: 0;
        padding-bottom: 0;
        @supports(mask-image: url('../img/icons/icon-category.svg')) {
            padding-top: u($spacing-unit * 2);
            padding-bottom: u($spacing-unit * 2);
        }
    }

    &.headroom {
        @include mq ($until: $viewport--lg - 1) {
            .show-nav & {
                &.headroom--not-top {
                    .Header-main {
                        .Logo {
                            filter: grayscale(0%);
                            // transition: filter .6s;
                        }

                        margin-top: 30px;
                    }
                }
            }

        }

        &--not-top {
            @include mq ($until: $viewport--lg - 1) {
                .Header-top {
                    position: fixed;
                    top: 0;
                    width: 100%;
                }
            }

            .Header-main {
                position: fixed;
                width: 100%;
                transition: padding .6s, box-shadow .6s, margin .6s;

                @supports(mask-image: url('../img/icons/icon-category.svg')) {
                    padding-top: u($spacing-unit);
                    padding-bottom: u($spacing-unit);
                }

                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
                top: 0;
                left: 0;
                right: 0;

                .Logo {
                    filter: grayscale(100%);
                    transition: filter .6s;
                }
            }
        }
    }

    &-top {
        background-image: $cc-gradient;
        height: 30px;
        display: flex;

        >.Container {
            justify-content: flex-end;

            a {
                padding: 0 20px;

                @include mq($until: 380px) {
                    padding: 0 5px;
                }

                position: relative;
                color: $white;
                font-family: $font-sofiaPro;
                font-weight: 600;

                display: flex;
                height: 30px;
                align-items: center;

                border-left: 2px solid rgba(255, 255, 255, 0.4);

                &:last-child {
                    border-right: 2px solid rgba(255, 255, 255, 0.4);
                }
            }
        }

    }

    &-main {
        position: absolute;
        top: 30px;
        width: 100%;
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);
        background-color: $white;
        transition: padding .6s, box-shadow .6s;

        .Logo {
            filter: grayscale(0%);
            transition: filter .6s;
        }
    }

    .Container {
        display: flex;
        align-items: center;
    }

    // ToggleNav
    @include mq($until: $viewport--lg - 1) {

        &::after {
            @include transition(#{opacity, visibility});
            background-color: $white;
            content: '';

            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: -1;

            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
